<template>
   <v-dialog
    v-model="dialog"
    :width="dialogWidth"
    persistent
    fullscreen
    >
      <v-card class="card">
        <v-card-title style="background-color: #4CAF50; color: white;">
          Nova Senha
        </v-card-title>
        <br>
        <div class='labeltrocasenha'>
          {{userName}}
        </div>
        <div class="max-size">
          <div class='tocenter'>
            {{mensagemtrocasenha}}
          </div>
          <br/>
          <core-password-field
              type='password'
              label='Senha Atual'
              :class="{ hometxfield: true, ['borda-alerta']: alertaAtual }"
              @click="alertaAtual = false"
              id='pass3'
              v-model="formulario.password"
              :color='color'
              dense
              hide-details
              >
            </core-password-field>
            <v-divider></v-divider>
            <div class="aviso-instrucoes">
            <ul>
              <li v-for="(item, index) in instrucoes" :key="index">
                {{item}}
              </li>
            </ul>
            </div>
            <div>
              <br>
              <core-password-field
                ref="input"
                label="Nova Senha"
                type=tel
                pattern="numeric"
                v-model="formulario.newpassword"
                @click="alerta = false"
                @keydown='TryLimit'
                id='pass1'
                :class="{ hometxfield: true, ['borda-alerta']: alerta }"
                :color='color'
                dense
                hide-details
              />
            </div>
            <div>
              <div>
                <core-password-field
                  type='password'
                  label='Repita a Nova Senha'
                  :class="{ hometxfield: true, ['borda-alerta']: alerta }"
                  id='pass2'
                  v-model="formulario.confirmnewpassword"
                  @click="alerta = false"
                  :color='color'
                  dense
                  hide-details
                  >
                </core-password-field>
              <div class="aviso-senha" v-if="erros">
                <ul>
                  <li v-for="(item, index) in erros" :key="index">
                    {{item}}
                  </li>
                </ul>
              </div>
            </div>
            <v-card-actions class="acoes-mobile" v-if="$_isMobile">
              <v-spacer></v-spacer>
              <div>
              <v-btn  class='black--text'
                text
                @click="$_sair">
                Voltar
              </v-btn>
              </div>
              <v-btn class='white--text'
                color="blue-grey"
                @click="$_validate()">
                Salvar
              </v-btn>
            </v-card-actions>
            <v-card-actions class="acoes" v-else>
              <v-btn  class='black--text'
                text
                @click="$_sair">
                Voltar
              </v-btn>
              <v-btn class='white--text'
                color="blue-grey"
                @click="$_validate()">
                Salvar
              </v-btn>
            </v-card-actions>
          </div>
        </div>
      </v-card>
    </v-dialog>
</template>

<script>

import { mapActions } from 'vuex';

import * as STORAGE from '@/constants/STORAGE';

import storage from '@/services/storage';

import errorHandler from '@/utils/error-handler';

import CorePasswordField from '@/components/core/password-field/Index.vue';

export default {
  name: 'DialogTrocaSenha',

  components: {
    CorePasswordField,
  },

  props: {
    dialogWidth: {
      type: Number,
      default: 550,
    },
  },

  data() {
    return {
      dialog: false,
      userName: undefined,
      color: 'green',
      now: null,
      os: null,
      formulario: {
        id: null,
        newpassword: '',
        confirmnewpassword: '',
        password: '',
        app: true,
      },
      erros: null,
      mensagemtrocasenha: null,
      instrucoes: null,
      alerta: null,
      alertaAtual: null,
    };
  },

  methods: {
    ...mapActions({
      $_findUser: 'users/findById',
      $_updateUser: 'users/newUpdateById',
      $_validaSenhaForte: 'users/validaSenhaForte',
      $_logout: 'auth/logout',
    }),

    async $_getUserName() {
      this.formulario.id = (await storage.getItem(STORAGE.TOKEN)).userId;
      const user = await this.$_findUser({ id: this.formulario.id });
      this.mensagemtrocasenha = sessionStorage.getItem('troca-senha');
      this.userName = user.usenome;
      if (this.mensagemtrocasenha) {
        this.instrucoes = sessionStorage.getItem('troca-senha-instrucoes');
        this.instrucoes = this.instrucoes.replaceAll('"', '').split('#');
        this.mensagemtrocasenha = this.mensagemtrocasenha.replaceAll('"', '');
        this.dialog = true;
      }
    },

    async $_validate() {
      // const senha = this.formulario.newpassword;
      // errorLog = senha === cSenha ? false : 'As senhas não coincidem.';
      // errorLog = senha.length === 8 ? errorLog : 'A senha deve conter 8 dígitos.';
      // errorLog = !Number.isNaN(Number(senha)) ? errorLog :
      // 'A senha deve conter apenas números';
      try {
        const validacao = await this.$_validaSenhaForte(this.formulario);
        console.log(validacao);
        errorHandler('Senha trocada com sucesso!');
        this.dialog = false;
      } catch (err) {
        this.erros = err.response.data.message.split('#');
        if (err.response.data.message.includes('atual')) {
          this.alertaAtual = true;
        }
        this.alerta = true;
      }
    },

    async TryLimit(e) {
      const { value } = e.currentTarget.value;
      if (e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft'
      || e.key === 'ArrowLeft' || e.key === 'ArrowRight'
      ) {
        return;
      }
      if (value) {
        if (value.length === 6) {
          this.color = 'red';
          setTimeout(() => {
            this.color = 'green';
          }, 3000);
        } else {
          this.color = 'green';
        }
      }
    },

    async $_sair() {
      await this.$_logout();
      return window.location.reload();
    },
  },

  created() {
    this.$_getUserName();
  },

  computed: {
    $_isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

<style scoped>

  .labeltrocasenha {
    display: flex;
    justify-content: center;
    font-weight: bold;
  }

  .tocenter {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .hometxfield {
    left: 5%;
    width: 90%;
    height: 100%;
  }

  .v-text-field input {
    font-size: 1.9em;
  }

  .aviso-instrucoes {
    width: 90%;
    margin: auto;
    margin-bottom: 10px;
    font-size: 0.8rem;
  }

  .aviso-senha {
    width: 90%;
    margin: auto;
    margin-bottom: 10px;
    font-size: 0.8rem;
    color: red;
  }

  .acoes-mobile {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .acoes {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .borda-alerta {
    border: 1px solid rgba(0, 0, 0, 0);
    animation: vibracao 0.5s;
  }

    @keyframes vibracao {
    0% {
      border-color: red;
      transform: translateX(10px);
    }

    20% {
      border-color: red;
      transform: translateX(-10px);
    }

    40% {
      border-color: red;
      transform: translateX(10px);
    }

    60% {
      border-color: red;
      transform: translateX(-10px);
    }

    80% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(0px);
    }
  }

  .max-size {
    max-width: 600px;
    margin: auto;
  }

</style>
