/* Funcao padrao para gerar datas no app */

import moment from "moment-timezone";

export default function geraDataV2({
  date = new Date(),
  mascara = 'YYYY-MM-DD HH:mm:ss.SSS',
  timezone = 'America/Sao_Paulo',
  add = 0,
  sub = 0,
  ignoreDev = false,
} = {}) {
    return moment(date).tz(timezone).add((ignoreDev) ? 0 : add, 'hours').subtract((ignoreDev) ? 0 : sub, 'hours').format(mascara);
}