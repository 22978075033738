import db from "@/plugins/dexie";
import { WsEnviaDados } from "../../websocket";
import geraData from "@/utils/geradata";

export default {
    enviaNotaFiscal: () => {
        const notafiscal = db.notafiscal.where('nofidnuvem').equals('null').toArray();

        notafiscal.then(async (tabela) => {
            const notafiscalEmissao = await db.notafiscal
            .where({ nofnronota: 'null'})
            //.and((item) => item.nofdataemissao !== undefined)
            .toArray();
            const notas = [...tabela, ...notafiscalEmissao]
            console.log('merge', notas)
            if (notas.length !== 0) {
                await WsEnviaDados({tipo: 'postNotaFiscal', msg: notas})
            }
        });
    },
    retornoNotaFiscal: ({ data }) => {
        const item = data.body;
        // query pela DataCriacao para fazer um handshake entre a WS para atribuir o idnuvem no lancamento
        const queryPromise = db.notafiscal.where({ nofdataatualizacao: geraData(item.nofdataatualizacao) }).toArray();
        queryPromise.then(async (tabela) => {
        for await (const linha of tabela) {
          await db.notafiscal.update(linha.id, {nofidnuvem: item.id, nofidusuario: item.nofidusuario});
          await db.notafiscalitem.where({nfidatanotafiscal: geraData(linha.nofdatacriacao)}).toArray().then(async (items) => {
            for await (const nfi of items) {
              await db.notafiscalitem.update(nfi.id, {nfiidnuvemnotafiscal: item.id})
            }
            const notafiscalitems = await db.notafiscalitem.where({nfiidnuvemnotafiscal: item.id}).toArray();
            notafiscalitems.filter((n) => !n.nfiidnuvem);
            WsEnviaDados({tipo: 'postNotaFiscalItem', msg: notafiscalitems})
            })
        }
      })
    },
}