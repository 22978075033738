import { nomesTabelas } from "../../tabelas"
import { prefixos } from "../../tabelas";
import db from "@/plugins/dexie";
import { WsEnviaDados } from "../../websocket";
import { WSModel } from "../../wsmodels";
import lancamentovisitatecnica from "../tabelas/lancamentovisitatecnica";

export default {
    enviaDataAtt: async ({ data }) => {
        const token = JSON.parse(sessionStorage.getItem('auth:token'));
        const datasAtt = await Promise.all(nomesTabelas.map(async (item) => {
            if (item.tabela === 'logexclusao') {
                const table = await db.logexclusao.toArray();
                return { adeletar: table, tabela: item.tabela };
            }
            const dataatt = await db[item.tabela]
                .orderBy(`${prefixos[item.tabela].prefixo}dataatualizacao`)
                .reverse()
                .first();
            if (dataatt) {
                return { att: dataatt[`${prefixos[item.tabela].prefixo}dataatualizacao`], userId: token.userId, tabela: item.tabela, userOnly: !!item.userOnly };
            }
            return { att: '', userId: token.userId, tabela: item.tabela, userOnly: !!item.userOnly };

        }));
        console.log(datasAtt);
        if (datasAtt.length > 0) {
            WsEnviaDados({ tipo: 'verificadataatt', msg: datasAtt, connectionId: data })
        }
    },
    updategeral: async function ({ data }) {
        data.forEach(async (alteracao) => {
            // Procurar alguma forma de trazer apenas uma propriedade
            const itensTabelalocal = await db[alteracao.tabela].toArray();
            alteracao.entities.forEach((entity) => {
                if (alteracao.tabela === 'logexclusao') {
                const exclusao = db[entity.loetabela].where(
                    `${prefixos[entity.loetabela].prefixo}idnuvem`,
                ).equals(entity.loeidnuvem).delete();
                exclusao.then((deletou) => {
                    if (deletou) {
                        // db.logexclusao.clear();
                        db.logexclusao.add(WSModel.logexclusao(entity));
                    }
                });
                } else {
                const campoIdNuvem = `${prefixos[alteracao.tabela].prefixo}idnuvem`;
                let existe = false;
                itensTabelalocal.forEach((itemTabelaLocal) => {
                    if (itemTabelaLocal[campoIdNuvem] === entity.id) {
                        existe = itemTabelaLocal.id;
                    }
                });
                setTimeout(() => {
                    if (existe) {
                        db[alteracao.tabela].update(existe, WSModel[alteracao.tabela](entity));
                    } else {
                        db[alteracao.tabela].add(WSModel[alteracao.tabela](entity));
                    }
                    }, 0);
                }
            });
        });
        //
        setTimeout(async () => {
            this.sincronizaLocal();
        }, 1000);
    },
    sincronizaLocal: async function() {
        const aAtualizar = nomesTabelas.filter((item) => {
            return ['lancamentoatividade', 'lancamentovisitatecnica', 'logexclusao','notafiscalitem']
            .includes(item.tabela);
        });

        const post = {};
        const put = {};
    
        for (const item of aAtualizar) {
            post[item.tabela] = []
            put[item.tabela] = []
            const tabela = await db[item.tabela].toArray();

            for (const linha of tabela) {
                const campodata = linha[`${prefixos[item.tabela].prefixo}datanuvem`];
                if (!campodata || (campodata === 'null')) {     
                    if (item.tabela === 'logexclusao') {
                        post[item.tabela].push(linha);
                    } else if ((item.tabela === 'lancamentovisitatecnica') && (linha.lvtimagem || linha.lvtvideo || linha.lvtaudio)) {
                        await lancamentovisitatecnica.envioComMidia({
                            dados: linha, 
                        });
                    } else {
                        post[item.tabela].push(linha)
                    }
                } else if (linha[`${prefixos[item.tabela].prefixo}dataatualizacao`] > campodata) {
                    if ((item.tabela === 'lancamentovisitatecnica') && (linha.lvtimagem || linha.lvtvideo || linha.lvtaudio)) {
                        await lancamentovisitatecnica.envioComMidia({
                            dados: linha,
                            update: true,
                        });
                    } else {
                        put[item.tabela].push(linha)
                    }
                }
            }
        }
        if (post['notafiscalitem']?.length) await WsEnviaDados({ tipo: 'postNotaFiscalItem', msg: post['notafiscalitem']});
        if (post['lancamentoatividade']?.length) await WsEnviaDados({ tipo: 'postLancamentoAtividade', msg: post['lancamentoatividade'] });
        if (put['lancamentoatividade']?.length) await WsEnviaDados({ tipo: 'updateLancamentoAtividade', msg: put['lancamentoatividade'] });
        if (post['lancamentovisitatecnica']?.length) await WsEnviaDados({ tipo: 'postLancamentoVisitaTecnica', msg: post['lancamentovisitatecnica'] });
        if (put['lancamentovisitatecnica']?.length) await WsEnviaDados({ tipo: 'updateLancamentoVisitaTecnica', msg: put['lancamentovisitatecnica'] });
        if (post['logexclusao']?.length) await WsEnviaDados({ tipo: 'postLogExclusao', msg: post['logexclusao'] });
    },
    sincronizaMidias: async ({ data }) => {
        const aSincronizar = nomesTabelas.filter((item) => {
            return ['lancamentovisitatecnica']
            .includes(item.tabela);
        });
        const dados = [];
        for (const item of aSincronizar) {
            const arrayMidias = (await db[item.tabela]
                .where({ [`${prefixos[item.tabela].prefixo}aguardandomidia`]: 'S' })
                .toArray()
            ).map((item) => item.id);
            
            
            dados.push({
                tabela: item.tabela,
                msg: arrayMidias,
            });
        }
        await WsEnviaDados({tipo: 'verificamidias', msg: dados, connectionId: data});
    }
}