<template>
  <core-view class="flex-column" fill-height>
    <template class="wrapper">
      <div class="center" v-if="!loading">
        <v-form ref="form" lazy-validation>
          <div class="linha">
            <v-btn
              color="green"
              class="white--text botaocamera"
              @click="$_cliqueCamera('', true)"
            >
              <v-icon large> mdi-camera </v-icon>
            </v-btn>
            <!-- TESTE -->
            <v-btn
              color="green"
              class="white--text botaocamera"
              @click="$_cliqueGravacao('')"
            >
              <v-icon large> mdi-film </v-icon>
            </v-btn>
            <!-- TESTE -->
            <v-btn
              color="green"
              class="white--text botaocamera"
              @click="$_cliqueObservacao('')"
            >
              <v-icon large> mdi-clipboard-text </v-icon>
            </v-btn>
            <v-btn
              color="green"
              class="white--text botaocamera"
              @click="$_cliqueAudio('')"
            >
              <v-icon large> mdi-microphone </v-icon>
            </v-btn>
          </div>
          <br />
          <br />
          <v-divider />
          <br />

          <template :activator="cardobs">
            <div v-if="lancamentos.length === 0" class="my-5 center">
              Nenhum lançamento foi encontrada.
            </div>

            <div 
            class="scroll center"
            id="cardcontainer">
              <v-card
                class="card mx-auto"
                outlined
                v-for="(item, index) in lancamentos"
                :key="index"
                ref="cardobs"
                @click="$_cliqueCard(index)"
              >
                <div class="header-talhao">
                  <b> {{ item.lvtnometalhao }} </b>
                  <div>
                    {{ $_geraData(item.lvtdatalancamento, "HH:mm:ss") }}
                    <v-icon dark> mdi-calendar </v-icon>
                  </div>
                </div>
                <v-card-title class="title" v-if="item.lvtnome">
                  {{ item.lvtnome }}
                </v-card-title>
                <v-img
                  v-if="$$_isMobile && item.lvtimagem"
                  max-width="300px"
                  max-height="200px"
                  class="mx-auto"
                  contain
                  :src="item.lvtimagem"
                />
                <v-img
                  v-else-if="item.lvtimagem"
                  max-width="500px"
                  max-height="300px"
                  class="mx-auto"
                  contain
                  :src="item.lvtimagem"
                />
                <div class="video-container">
                  <video
                    v-if="$$_isMobile && item.lvtvideo"
                    class="video-el"
                    controls
                    metadata
                    :src="item.lvtvideo"
                  />
                  <video
                    v-else-if="item.lvtvideo"
                    class="video-el"
                    controls
                    metadata
                    :src="item.lvtvideo"
                  />
                </div>
                <v-card-text class="descricao" v-if="item.lvtdescricao">{{item.lvtdescricao}}
                </v-card-text>
                <audio
                  class="card-audio"
                  v-if="item.lvtaudio"
                  :src="$_geraURLAudio(item.lvtaudio)"
                  controls
                ></audio>
                <v-card-text v-if="item.lvtobservacao" class="card-observacao"
                  >{{ item.lvtobservacao }}
                </v-card-text>
              </v-card>
            </div>
          </template>

          <v-dialog v-model="dialog" @click:outside="$_sairDialog()">
            <v-card-title
              class="header headline green white--text"
              id="card-superior"
            >
              <div class="header" id="label">Editar Lançamento</div>
              <v-icon class="mx-1 my-1" id="icone"> mdi-pencil </v-icon>
            </v-card-title>
            <div class="center scrollDialog">
              <v-card class="content">
                <div class="video-container">
                  <video
                  v-if="novoVideoSrc"
                  :src="novoVideoSrc"
                  controls
                  class="video-el"
                  />     
                </div>
                <v-icon 
                @click="$_cliqueCamera('alterar')" 
                v-if="novoVideoSrc"
                class="iconedit"
                  >mdi-image-edit</v-icon
                >
                <br v-if="novoVideoSrc"/>
                <div>
                  <v-select 
                  label="Talhão"
                  v-if="novoTalhao"
                  outlined
                  :items="autocomplete.talhao"
                  item-text="taldescricao"
                  item-value="talidtalhao"
                  @change="$_setUltimoTalhao($event)"
                  v-model="novoTalhao"
                  />
                  <v-text-field
                    v-if="novoTitle"
                    v-model="novoTitle"
                    :rules="rules.titulo"
                    outlined
                    dense
                    counter="25"
                    maxlength="25"
                    autocomplete="off"
                    class="dialoginput"
                    label="Titulo da Imagem"
                  >
                  </v-text-field>
                  <audio
                  class="novo-audio"
                  v-if="novoAudio"
                  :src="$_geraURLAudio(novoAudio)"
                  controls> 
                  </audio>
                  <v-textarea
                    v-if="novoDesc"
                    v-model="novoDesc"
                    class="dialoginput descDialog"
                    dense
                    counter="200"
                    rows="2"
                    maxlength="200"
                    outlined
                    label="Descrição"
                  >
                  </v-textarea>
                  <v-textarea
                    v-if="novoObs"
                    v-model="novoObs"
                    class="dialoginput descDialog no-resize"
                    dense
                    counter="200"
                    maxlength="200"
                    outlined
                    label="Observação"
                  >
                  </v-textarea>
                </div>

                <v-img
                  v-if="$$_isMobile && novoImgSrc"
                  width="300px"
                  height="250px"
                  class="mobiledialog mx-auto"
                  contain
                  :src="novoImgSrc"
                >
                  <v-icon 
                  @click="$_cliqueCamera('alterar')" 
                  class="iconedit"
                    >mdi-image-edit</v-icon
                  >
                </v-img>

                <v-img
                  v-else-if="novoImgSrc"
                  width="600px"
                  height="350px"
                  class="imgdialog mx-auto"
                  contain
                  :src="novoImgSrc"
                >
                  <v-icon 
                  @click="$_cliqueCamera('alterar')"
                  class="iconedit"
                    >mdi-image-edit</v-icon
                  >
                </v-img>

                <!-- <v-icon 
                v-if="novoVideoSrc"
                @click="$_cliqueVideo('alterar')" 
                class="iconedit"
                  >mdi-tag-edit</v-icon
                > -->

                <div class="divinferior">
                  <v-btn
                    height="30"
                    small
                    class="red botaoinferior"
                    @click="$_confirmarExclusao()"
                  >
                    Excluir
                  </v-btn>
                  <v-btn
                    height="30"
                    small
                    class="green botaoinferior"
                    @click="$_salvaDados()"
                  >
                    Salvar
                  </v-btn>
                </div>
              </v-card>
            </div>
          </v-dialog>

          <v-dialog class="exclusao" max-width="400px" v-model="dialogExclusao">
            <v-card-title
              class="header headline red white--text"
              id="card-superior"
            >
              <div class="header" id="label">Excluir Observação</div>
              <v-icon id="icone"> mdi-alert </v-icon>
            </v-card-title>
            <div>
              <v-card class="mx-auto">
                <div class="text-center">
                  <v-card-text class="confirmacao">
                    Deseja realmente excluir esta observação?
                  </v-card-text>
                </div>

                <div class="divinferior">
                  <v-btn
                    height="30"
                    small
                    id="voltar"
                    class="botaoinferior my-5"
                    @click="dialogExclusao = false"
                  >
                    Voltar
                  </v-btn>
                  <v-btn
                    height="30"
                    small
                    class="red botaoinferior my-5"
                    @click="$_excluirDados()"
                  >
                    Excluir
                  </v-btn>
                </div>
              </v-card>
            </div>
          </v-dialog>
        </v-form>
        <v-dialog v-model="dialogObservacao" max-width="400px">
          <v-card>
            <v-card-title class="header headline green white--text">
              Nova Observação
            </v-card-title>
            <br />
            <v-card-text>
              <v-form ref="formobservacao">
                <v-select 
                  label="Talhão"
                  outlined
                  :items="autocomplete.talhao"
                  item-text="taldescricao"
                  item-value="talidtalhao"
                  @change="$_setUltimoTalhao($event)"
                  v-model="talhao"
                />
                <v-textarea
                  v-model="observacao"
                  outlined
                  counter="200"
                  :rules="rules.observacao"
                >
                </v-textarea>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-between">
              <v-btn 
              @click="dialogObservacao = false"> Voltar </v-btn>
              <v-btn color="primary" @click="$_salvarObservacao">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAudio" bottom max-width="400px">
          <v-card>
            <v-card-title class="header headline green white--text">
              Novo Áudio
            </v-card-title>
            <br />
            <v-card-text>
            <v-select 
            label="Talhão"
            outlined
            :items="autocomplete.talhao"
            item-text="taldescricao"
            item-value="talidtalhao"
            @change="$_setUltimoTalhao($event)"
            v-model="talhao"
            :rules="rules.talhao"
            />
            <v-text-field
              v-model="novoTitle"
              :rules="rules.titulo"
              outlined
              dense
              counter="25"
              maxlength="25"
              autocomplete="off"
              class="dialoginput"
              label="Titulo do Áudio"
            />
              <audio-recorder
              ref="audio-recorder"
              @stop="$_setAudioAtual">
              </audio-recorder>
              <br/>
              <v-textarea
                v-model="novoDesc"
                class="dialoginput descDialog"
                dense
                counter="200"
                rows="2"
                maxlength="200"
                outlined
                label="Descrição do Áudio"
              />
            </v-card-text>
            <v-card-actions class="d-flex justify-space-between">
              <v-btn 
              @click="dialogAudio = false"> Voltar </v-btn>
              <v-btn 
              color="red" 
              :style="{ color: 'white'}"
              :disabled="!audioAtual"
              @click="$_deletarAudioAtual"> Deletar </v-btn>
              <v-btn 
              color="primary" 
              :disabled="!audioAtual"
              @click="$_salvarAudio"> Salvar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </core-view>
</template>
<script>
import CoreView from "@/components/core/view/Index.vue";
import AudioRecorder from "@/components/core/audio-recorder/Index.vue";
import { VISITA_STORAGE, VISITA_INFO_STORAGE } from "@/constants/STORAGE";
import moment from "moment-timezone";
import db from "@/plugins/dexie";
import { ROUTE_CAMERA, ROUTE_VIDEO } from "@/constants/ROUTES";
import geraData from "@/utils/geradata";
import errorHandler from "@/utils/error-handler";
import { mapActions, mapGetters } from 'vuex';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: "ViewVisitaInfo",
  components: {
    CoreView,
    AudioRecorder,
  },
  data() {
    return {
      lancamentos: [],
      dialog: false,
      dialogExclusao: false,

      novoTitle: null,
      novoImgSrc: null,
      novoVideoSrc: null,
      novoDesc: null,
      novoObs: null,
      novoAudio: null,
      novoIndex: null,
      novoTalhao: null,

      loading: true,
      cardobs: null,
      visita: {
        cultura: null,
        produtor: "",
        propriedade: "",
        data: moment().format("YYYY-MM-DD"),
      },
      imagemAnterior: null,

      rules: {
        titulo: [(v) => !!v || "Titúlo é obrigatório"],
        observacao: [
          (v) =>
            v.length <= 200 ||
            "Observação não pode ser maior que 200 caracteres",
        ],
        talhao: [(v) => !!v || 'Talhão é obrigatório'],
      },

      dialogObservacao: false,
      observacao: "",

      dialogAudio: false,
      audioAtual: null,
      
      videoAtual: null,

      autocomplete: {
        talhao: null,
      },

      talhao: null,
    };
  },
  computed: {
    $$_isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    ...mapGetters({
      $$_blobUrls: 'visitatecnica/blobUrls',
      $$_ultimoTalhao: 'visitatecnica/ultimoTalhao',
    })
  },
  methods: {
    ...mapActions({
      $_setBlobUrl: 'visitatecnica/setBlobUrL',
      $_setUltimoTalhao: 'visitatecnica/setUltimoTalhao',
    }),
  
    $_cliqueCard(idimg) {
      this.novoTitle = this.lancamentos[idimg].lvtnome;
      this.novoImgSrc = this.lancamentos[idimg].lvtimagem;
      this.novoVideoSrc = this.lancamentos[idimg].lvtvideo;
      this.novoDesc = this.lancamentos[idimg].lvtdescricao;
      this.novoObs = this.lancamentos[idimg].lvtobservacao;
      this.novoAudio = this.lancamentos[idimg].lvtaudio;
      this.novoTalhao = this.lancamentos[idimg].lvtidtalhao;
      this.novoIndex = idimg;
      sessionStorage.setItem(
        VISITA_INFO_STORAGE.titulo,
        this.lancamentos[idimg].lvtnome
      );
      sessionStorage.setItem(
        VISITA_INFO_STORAGE.observacao,
        this.lancamentos[idimg].lvtdescricao
      );
      sessionStorage.setItem(
        VISITA_INFO_STORAGE.idinfo,
        this.lancamentos[idimg].id
      );
      this.dialog = true;
    },
  
    async $_SetupCamposReadOnly(idvisita) {
      this.loading = true;
      const lancamentos = await db.lancamentovisitatecnica
      .where({ lvtidvisita: String(idvisita) })
      .toArray();
      const talhaoKeyMap = {};
      this.lancamentos = await lancamentos.map((item) => {
        let finalItem = item;
        if (item.lvtvideo) {
          if (this.$$_blobUrls(item.id)) {
            console.log(`Urls do video ${item.id} foi recuperada do cache`);
            finalItem = {
              ...finalItem,
              lvtvideo: this.$$_blobUrls(item.id),
            }
          } else {
            const url = URL.createObjectURL(item.lvtvideo);
            this.$_setBlobUrl({ id: item.id, url });
            finalItem =  {
              ...finalItem,
              lvtvideo: url, 
            }
          }
          //
        }
          if (item.lvtidtalhao) {
            if (talhaoKeyMap[item.lvtidtalhao]) {
              finalItem.lvtnometalhao = talhaoKeyMap[item.lvtidtalhao].taldescricao;
            } else {
              talhaoKeyMap[item.lvtidtalhao] = this.autocomplete.talhao.find((el) => {
                return el.talidtalhao === item.lvtidtalhao;
              });
              finalItem.lvtnometalhao = talhaoKeyMap[item.lvtidtalhao].taldescricao;
            }
          }
          return finalItem;
      });
      setTimeout(() => {
        const cardContainer = document.getElementById('cardcontainer');
        console.log(cardContainer);
        cardContainer.scrollTop = cardContainer.scrollHeight;
      },);
      this.loading = false;
    },
    $_cliqueCamera(acao, toCam) {
      if (acao == "alterar") {
        sessionStorage.setItem(VISITA_INFO_STORAGE.observacao, this.novoDesc);
        sessionStorage.setItem(VISITA_INFO_STORAGE.titulo, this.novoTitle);
        db.lancamentovisitatecnica.where("id");
      } else {
        ["observacao", "titulo", "idinfo", "imagem"].forEach((item) => {
          sessionStorage.removeItem(VISITA_INFO_STORAGE[item]);
        });
      }
      if (toCam) {
        this.novoImgSrc = null;
      }
      if (this.novoImgSrc || toCam) {
        this.$router.push({
          ...this.$route,
          name: ROUTE_CAMERA.name,
        });
      } else if (this.novoVideoSrc) {
        this.$router.push({
          ...this.$route,
          name: ROUTE_VIDEO.name,
        });
      }
    },
    $_cliqueVideo(acao) {
      if (acao == "alterar") {
        sessionStorage.setItem(VISITA_INFO_STORAGE.observacao, this.novoDesc);
        sessionStorage.setItem(VISITA_INFO_STORAGE.titulo, this.novoTitle);
        db.lancamentovisitatecnica.where("id");
      } else {
        ["observacao", "titulo", "idinfo", "imagem"].forEach((item) => {
          sessionStorage.removeItem(VISITA_INFO_STORAGE[item]);
        });
      }
      this.$router.push({
        ...this.$route,
        name: ROUTE_VIDEO.name,
      });
    },
    $_cliqueGravacao(acao) {
      if (acao == "alterar") {
        sessionStorage.setItem(VISITA_INFO_STORAGE.observacao, this.novoDesc);
        sessionStorage.setItem(VISITA_INFO_STORAGE.titulo, this.novoTitle);
        db.lancamentovisitatecnica.where("id");
      } else {
        ["observacao", "titulo", "idinfo", "imagem"].forEach((item) => {
          sessionStorage.removeItem(VISITA_INFO_STORAGE[item]);
        });
      }
      this.$router.push({
        ...this.$route,
        name: ROUTE_VIDEO.name,
      });
    },
    async $_cliqueObservacao(acao) {
      this.dialogObservacao = true;
      if (acao == "alterar") {
        //
      } else {
        //
      }

    },
    async $_cliqueAudio(acao) {
      this.dialogAudio = true;
      if (acao == "alterar") {
        //
      } else {
        //
      }
    },
    
    async $_salvarObservacao() {
      if (this.$refs.formobservacao.validate()) {
        db.lancamentovisitatecnica.add({
          lvtidvisita: this.idvisita,
          lvtdatalancamento: geraData(),
          lvtdataatualizacao: geraData(),
          lvtobservacao: this.observacao,
          lvtidtalhao: this.talhao,
          lvtaguardandomidia: 'N',
          lvtdatanuvem: 'null',
          lvtidusuario: sessionStorage.getItem('user:useidnuvem', null),
        });
        this.$_SetupCamposReadOnly(this.idvisita);
        this.dialogObservacao = false;
        this.observacao = "";
      }
    },

    $_setAudioAtual(audio) {
      errorHandler(audio);
      this.audioAtual = audio;
    },

    async $_salvarAudio() {
      errorHandler(this.audioAtual.blob);
      if (this.audioAtual) {
        db.lancamentovisitatecnica.add({
          lvtidvisita: this.idvisita,
          lvtdatalancamento: geraData(),
          lvtdataatualizacao: geraData(),
          lvtdescricao: this.novoDesc,
          lvtnome: this.novoTitle,
          lvtaudio: this.audioAtual.blob,
          lvtidtalhao: this.talhao,
          lvtaguardandomidia: 'N',
          lvtdatanuvem: 'null',
          lvtidusuario: sessionStorage.getItem('user:useidnuvem', null),
        });
        this.$_SetupCamposReadOnly(this.idvisita);
        this.dialogAudio = false;
        this.audioAtual = "";
      }
    },

    async $_deletarAudioAtual() {
      this.$refs['audio-recorder'].$_deleteRecording();
      this.audioAtual = null;
    },

    $_geraURLAudio(audioBlob) {
      try {
        if (audioBlob) {
          return URL.createObjectURL(audioBlob);
        }
      } catch (e) {
        errorHandler(e);
      }
    },

    async $_sairDialog() {
      const idimg = parseInt(
        getSessionStorage(VISITA_INFO_STORAGE.idinfo, null)
      );
      const alterarImagem = await db.lancamentovisitatecnica
        .where({ id: idimg })
        .first();
      console.log("alterar imagem", alterarImagem);

      if (this.imagemAnterior != null) {
        alterarImagem.lvtimagem = this.imagemAnterior;

        db.lancamentovisitatecnica.where({ id: idimg }).modify({
          lvtimagem: alterarImagem.lvtimagem,
        });
        this.lancamentos.findIndex((p) => {
          if (p.id === idimg) {
            p.lvtimagem = alterarImagem.lvtimagem;
          }
        });
      }
    },

    async $_salvaDados() {
      if (this.$refs.form.validate()) {
        const idimg = parseInt(
          getSessionStorage(VISITA_INFO_STORAGE.idinfo, null)
        );

        if (this.novoObs) {
            await db.lancamentovisitatecnica.where({ id: idimg }).modify({
              lvtobservacao: this.novoObs,
              lvtidtalhao: this.novoTalhao,
              lvtaguardandomidia: 'N',
              lvtdatanuvem: 'null',
              lvtidusuario: sessionStorage.getItem('user:useidnuvem', null),
              lvtdatalancamento: geraData(),
              lvtdataatualizacao: geraData(),
            });
        } else if (this.novoImgSrc || this.novoVideoSrc) {
            await db.lancamentovisitatecnica.where({ id: idimg }).modify({
              lvtdescricao: this.novoDesc,
              lvtnome: this.novoTitle,
              lvtidtalhao: this.novoTalhao,
              lvtaguardandomidia: 'N',
              lvtdatanuvem: 'null',
              lvtidusuario: sessionStorage.getItem('user:useidnuvem', null),
              lvtdatalancamento: geraData(),
              lvtdataatualizacao: geraData(),
            });
        }

        this.lancamentos.findIndex((p) => {
          if (p.id === idimg) {
            (p.lvdescricao = this.novoDesc), (p.lvtnome = this.novoTitle);
          }
        });
        this.$_SetupCamposReadOnly(this.idvisita);
        this.dialog = false;
      }
    },

    $_confirmarExclusao() {
      this.dialogExclusao = true;
    },

    async $_excluirDados() {
      const idimg = parseInt(
        getSessionStorage(VISITA_INFO_STORAGE.idinfo, null)
      );
      console.log(idimg, 'idimg');
      const adeletar = (await db.lancamentovisitatecnica.where({ id: idimg }).toArray())[0];

      console.log('adios', adeletar)
      if (adeletar) {
        db.logexclusao.add({
          loeidnuvem: adeletar.lvtidnuvem,
          loetabela: 'lancamentovisitatecnica',
          loedataexclusao: geraData(),
          loeidusuario: sessionStorage.getItem('user:useidnuvem', null),
          loedatanuvem: 'null',
        });
      }
      console.log()
      db.lancamentovisitatecnica.where({ id: idimg }).delete();

      this.dialogExclusao = false;
      this.dialog = false;
    },

    $_geraData(data, mascara) {
      return geraData(data, mascara);
    },

    async $_setupAutoComplete() {
      const dadosTalhao = db.talhao.toArray();
      dadosTalhao.then((dados) => {
        this.autocomplete.talhao = dados;
        this.novoTalhao = this.$$_ultimoTalhao;
      })
    },
  },

  beforeCreate() {
    const idvisita = getSessionStorage(VISITA_STORAGE.idvisita);
    this.idvisita = idvisita;
  },

  async created() {
    this.$_setupAutoComplete();
    this.$_SetupCamposReadOnly(Number(this.idvisita));

    // const idimg = parseInt(getSessionStorage(VISITA_INFO_STORAGE.idinfo, null));
    // if (getSessionStorage(VISITA_INFO_STORAGE.idinfo, null) != null) {
    //   this.novoDesc = getSessionStorage(VISITA_INFO_STORAGE.observacao, "");
    //   this.novoTitle = getSessionStorage(VISITA_INFO_STORAGE.titulo, "");
    //   this.novoImgSrc = (
    //     await db.lancamentovisitatecnica.where({ id: idimg }).first()
    //   ).lvtimagem;
    //   this.dialog = true;
    // }
  },
};
</script>

<style scoped>
.v-sheet--offset {
  top: 5px;
  position: relative;
}

#icone {
  color: white;
  float: right;
}

.readonlyfield {
  margin-bottom: 7%;
  font-size: 0.9em;
}
.center {
  width: 100%;
  text-align: center;
}

.linha {
  display: flex;
  margin-bottom: -30px;
  justify-content: space-around;
}

.divinferior {
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: absolute;
  bottom: 5px;
  right: 0%;
}

.botaoinferior {
  color: white;
}
#voltar {
  color: black;
  margin-bottom: 8px;
}

@media screen and (min-width: 600px) {
  .tablet {
    display: flex;
  }
}

.cardimages {
  display: flex;
  max-height: 80vh;
  flex-direction: column;
  overflow: scroll;
}
.botaocamera {
  margin: 10px;
}
.imgdialog {
  margin-top: 5px;
  margin-bottom: 40px;
}
.scroll {
  max-height: 75vh;
  overflow: scroll;
}
.scrollDialog {
  max-height: 80vh;
  overflow: scroll;
}
.title {
  justify-content: center;
  margin-bottom: -15px;
}

.wrapper {
  overflow-y: scroll;
}
.content {
  padding: 15px;
}
.card {
  margin-bottom: 5%;
  padding-bottom: 5%;
  width: 95%;
  max-width: 600px;
  
}
.descricao {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.dialoginput {
  font-size: 1rem;
}
.label {
  margin-top: 5%;
}
.header {
  font-size: 1.1rem;
  padding: 1%;
  padding-left: 5px;
  justify-content: space-between;
}
.confirmacao {
  margin-bottom: 20%;
  font-weight: bold;
  display: inline-block;
}
.mobiledialog {
  margin-top: -5%;
  margin-bottom: 12%;
}
.descDialog {
  margin-bottom: 5%;
}
.iconedit {
  float: right;
}
.header-talhao {
  display: flex;
  justify-content: space-between;
  background-color: #4caf50;
  color: white;
  padding-block: 2px;
  padding-inline: 5px;
}

.header-talhao > div:first-child {
}

.header-talhao > div:last-child {
}

.card-observacao {
  display: block;
  text-align: left;
  white-space: pre-wrap;
}

.card-audio {
  margin-top: 16px;
}

.novo-audio {
  margin-block: 35px;
}

.no-resize {
  resize: none;
}

.video-container {
  max-width: 500px;
  margin-inline: auto;
}

.video-el {
  width: 100%;
  height: 100%;
}
</style>