import axios from 'axios';

const api = axios.create({ baseURL: process.env.VUE_APP_API_HOST });

export default api;

export function apiSetToken(token) {
  api.defaults.headers.Authorization = token ? `Bearer ${token}` : null;
}

export function setupApiOnResponseError(responseInterceptor) {
  api.interceptors.response.use(null, responseInterceptor);
}

export async function setupBaseURL() {
  if (process.env.NODE_ENV !== 'production' || !navigator.onLine) return;
  const { data } = await axios.get('/api-base-url');
  api.defaults.baseURL = data;
}
