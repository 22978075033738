<template>
  <v-card width="95%" class="card" >
        <div class="between titulo">
          <h4 v-if="dados.nofnronota">
            {{dados.nofnronota}}
          </h4>
          <h4 class="data">
            {{$_FormataData(dados.nofdataatualizacao)}}
          </h4>
        </div>
        <v-divider></v-divider>
        <div class="subtitulo">
          <h4>
            {{dados.nofnomepessoa}}
          </h4>
          <h4>
            {{dados.nofplaca}}
          </h4>
          <h4>
            Emissão: {{$_FormataData(dados.nofdataemissao)}}
          </h4>
          <h4>
            Saída: {{$_FormataData(dados.nofdatasaida)}}
          </h4>
        </div>
        <div class="erro" v-if="dados.nofmensagemretornanfesefaz || dados.nofcodigoretornanfesefaz && dados.nofcodigoretornanfesefaz != 100">
          <v-btn color="red" class="erro">
              Erro
          </v-btn>
          <v-chip color="red" outlined label>{{dados.nofmensagemretornanfesefaz}}</v-chip>
        </div>
        <div class="between" v-else>
          <div class="btn-emitir">
            <v-btn color="primary" width="100%" @click="$_emitir()"
            v-if="!dados.nofnronota && !dados.nofdataemissao">
              <div v-if="!emitindo">Emitir</div>
              <v-progress-circular
                v-else
                indeterminate
                color="white"
              ></v-progress-circular>
            </v-btn>
            <v-btn v-else-if="!dados.nofnronota && dados.nofdataemissao" outlined>
              Em Processamento</v-btn>
            <v-btn class="btn-emitido" outlined v-else>
              Emitido
            </v-btn>
          </div>
        
          <div class="arquivos" v-if="dados.nofnronota && dados.nofcodigoretornanfesefaz == 100" transition="fade-transition">
            <v-btn smalldepressed small @click.prevent="$_baixarFile('xml')">
              XML
            </v-btn>
            <v-btn smalldepressed small @click.prevent="$_baixarFile('pdf')">
              PDF
            </v-btn>

            <v-btn smalldepressed class="share" small @click.prevent="$_share()">
              <v-icon>
                mdi-share-variant
              </v-icon>
            </v-btn>

            <v-dialog persistent v-model="dialog" max-width="500px" width="80%">
              <v-card-title
                    class="header headline green white--text justify-space-between"
              >
                <div v-if="$$_stateDialog" class="header" >Enviar Whatsapp</div>
                <div v-else class="header" >Enviar Email</div>

                <v-icon v-if="$$_stateDialog" large class="mx-1 my-1 icone"> mdi-whatsapp </v-icon>
                <v-icon v-else large class="mx-1 my-1 icone"> mdi-email </v-icon>
              </v-card-title>
              <div class="center">
              <v-card class="content">
                <v-form ref="form" lazy-validation @submit.prevent="">
                  
                  <div class="linha">
                    <v-card-title class="float-left">
                      Recipientes:
                    </v-card-title>
                  </div>
                  
                <div v-if="!produtortransporte">
                   <div class="linha">
                    <v-autocomplete label="Produtor"
                      v-model="produtor.nome"
                      outlined
                      append-icon="mdi-close"
                      @click:append="$_excluirDestinatario('produtor')"
                      :items="[dados.nofnomepessoa]"
                      @change="$_changeRecipiente('produtor')"
                    >
                    </v-autocomplete>
                  </div>

                   <div class="linha">
                    <v-autocomplete label="Transportador"
                      v-model="transportador.nome"
                      outlined
                      append-icon="mdi-close"
                      @click:append="$_excluirDestinatario('transportador')"
                      :items="[dados.nofnometransportador]"
                      @change="$_changeRecipiente('transportador')"
                    >
                    </v-autocomplete>
                  </div>
                </div>

                <div v-else>
                   <div class="linha">
                    <v-autocomplete label="Produtor e Transportador"
                      v-model="produtor.nome"
                      outlined
                      readonly
                      append-icon=""
                      :items="[dados.nofnomepessoa]"
                      @change="$_changeRecipiente('produtor')"
                    >
                    </v-autocomplete>
                  </div>
                </div>

                  <div width="100%" class="d-flex justify-space-between">
                  <v-btn
                    height="30"
                    small
                    class=""
                    @click="dialog = false"
                  >
                    Voltar
                  </v-btn>
                  <v-btn
                    height="30"
                    small
                    class="green botaoinferior"
                    @click="$$_stateDialog ? $_enviarWhatsapp() : $_enviarEmail()"
                  >
                  <div v-if="!enviando">
                    Enviar
                  </div>
                    <v-progress-circular
                      v-else
                      indeterminate
                      color="white" >
                    </v-progress-circular>
                  </v-btn>             
                </div>
                </v-form>
              </v-card>
              </div>
            </v-dialog>

            <v-bottom-sheet max-width="500px" v-model="bottom" v-if="bottom">
              <v-sheet class="border" height="6em">
              <div class="sheet">
                <v-btn @click="$_openDialog('email')" class="green fontwhite my-7">
                  <v-icon class="mx-1">
                    mdi-email-outline
                  </v-icon>
                  Email
                </v-btn>
                <v-btn @click="$_openDialog('whatsapp')" class="green fontwhite my-7">
                  <v-icon class="mx-1">
                    mdi-whatsapp
                  </v-icon>
                  Whatsapp
                </v-btn>
              </div>
              </v-sheet>
            </v-bottom-sheet>
          </div>
        </div>
        <v-progress-linear
          v-if="downloading"
          absolute
          indeterminate
          bottom
          height="3px"
          color="#4CAF50">
        </v-progress-linear>   
      </v-card>
</template>

<script>
// import errorHandler from '@/utils/error-handler';

import moment from 'moment';

import { IsOnline } from '@/utils/isonline';
import errorHandler from '@/utils/error-handler';
import { mapActions } from 'vuex';
import geraData from '@/utils/geradata';
import db from '@/plugins/dexie';


// import moment from 'moment-timezone';
/* let now = moment();
now = now.subtract(3, 'hours') */

export default {
  name: 'CardNFe',

  data() {
    return {
      teste: Boolean,
      emitindo: false,
      enviando: false,
      downloading: false,
      bottom: false,
      dialog: false,
      produtortransporte: false,
      produtor: {
        nome: String,
        email: String,
        numero: String
      },
      transportador: {
        nome: String,
        email: String,
        numero: String,
      },
      email: {
        destinatario: [],
      },
      whatsapp: [],
      
      stateDialog: null,
    };
  },

  computed: {
    $$_stateDialog() {
      // RETORNA UM VALOR TRUE QUANDO FOR ENVIO PARA O WHATSAPP
      // E QUANDO FOR PARA O EMAIL, RETORNA UM FALSE
      return this.stateDialog === 'whatsapp' ? true : false;
    }
  },
  methods: {
    ...mapActions({
      $_emiteNotaFiscal: 'notafiscal/updateComWebSocket',
      $_baixarNotaFiscal: 'notafiscal/findFiles',
      $_postEmail: 'notafiscal/enviarEmail',
      $_postWhatsapp: 'notafiscal/enviarWhatsapp'
    }),

    async $_share(){
      this.bottom = true;
    },

    async $_openDialog(dialog) {
      this.stateDialog = dialog
      this.bottom = false;
      this.dialog = true;
      //await this.$_validarDialog()
      await this.$_setupDestinatario()
    },

    $_FormataData(date) {
      // return moment(date, 'YYYY-MM-DD').format('DD/MM-HH:MM');
      return moment.tz(date, 'America/Sao_Paulo').local().format('DD/MM/YY - HH:mm');
    },

    async $_emitir() {
      this.emitindo = true;
      console.log('isonline', await IsOnline())
      const online = await IsOnline();
      console.log(online)
      if(online === true && this.dados.nofidnuvem === 'null') {
        errorHandler('A nota fiscal ainda esá sendo criada. Tente atualizar a página.')
        this.emitindo = false;

      }
      else if (online) {
        const dataemissao = geraData()
        // Atualização da DataEmissao no Dexie
        await db.notafiscal.update(this.dados.id, { 
          nofdataemissao: dataemissao,
          nofdataatualizacao: new Date().toISOString(),
          nofprocessamento: 'EM PROCESSAMENTO'
        });
        await this.$_emiteNotaFiscal({
          body: {
            id: this.dados.nofidnuvem,
            nofdataemissao: dataemissao,
          },
        }).catch((err) => {
          console.log(err)
        });
        this.$emit('envio')
        this.emitindo = false;
      } else {
        errorHandler('Falha ao emitir, sem conexão com a internet');
        this.emitindo = false;
      }
    },

    async $_baixarFile(tipo) {
      const online = await IsOnline();
      console.log(online)
      if(online) {
        this.downloading = true;
        const files = await this.$_baixarNotaFiscal({
          params: {
            serie: this.dados.nofserie,
            nro: this.dados.nofnronota,
          }
        })
        files.forEach((arquivo) => {
          if (arquivo.blob.type.split('/')[1] === tipo) {
            this.$_download(arquivo)
          }
        })
      } else {
        errorHandler('Falha no download do arquivo, sem conexão com a internet.')
        this.downloading = false;
      }
    },

    $_download(arquivo) {
      try {
        const url = window.URL.createObjectURL(arquivo.blob);
        const el = document.createElement('a');
        el.href = url;
        el.download = `${arquivo.name}`
        document.body.appendChild(el);
        el.click()
        window.URL.revokeObjectURL(url);
        document.body.removeChild(el);
      } catch (err) {
        console.log(err)
        errorHandler('Erro ao baixar a nota.')
      }
      this.downloading = false;
    },

    async $_enviarEmail() {
      const validacao = await this.$_validarDialog()
      if(validacao){
        this.enviando = true;
        try{
          const online = await IsOnline();
          if(online) {
            /// email: this.email dentro do body qnd for estiver na produção
            const envio = await this.$_postEmail({ 
              body: { 
                email: this.email.destinatario,
                serie: this.dados.nofserie,
                nro: this.dados.nofnronota,
                nfe: {
                  produtor: this.dados.nofnomepessoa,
                  numero: this.dados.nofnronota,
                  dataemissao: geraData(this.dados.nofdataemissao, 'YYYY-MM-DD HH:mm:ss'),
                  datasaida: geraData(this.dados.nofdatasaida, 'YYYY-MM-DD HH:mm:ss'),
                  serie: this.dados.nofserie,
                  total: new Intl.NumberFormat("pt-BR", {style:'currency', currency:'BRL', minimumFractionDigits: 4 }).format(this.dados.noftotal),
                  transportador: this.dados.nofnometransportador,
                }
              }
              });
              if (envio == 'Erro')  {
                errorHandler('Erro ao enviar o email.')
              } else {
                errorHandler('O email foi enviado com sucesso.')
                this.dialog = false;
              }
            this.enviando = false;
          } else {
            errorHandler('Falha ao enviar o email, sem conexão com a internet.')
            this.enviando = false; 
          }
        } catch(err) {
          console.log(err)
        }
      }
    }, 
    
    async $_enviarWhatsapp(){
      const validacao = await this.$_validarDialog()
      if(validacao){
        this.enviando = true;
        try{
          const online = await IsOnline();
          if(online) {
            /// email: this.email dentro do body qnd for estiver na produção
            const envio = await this.$_postWhatsapp({ 
              body: { 
                whatsapp: this.whatsapp,
                serie: this.dados.nofserie,
                nro: this.dados.nofnronota,
                nfe: {
                  produtor: this.dados.nofnomepessoa,
                  natureza: this.dados.nofidnaturezaoperacao,
                  dataemissao: geraData(this.dados.nofdataemissao, 'YYYY-MM-DD HH:mm:ss'),
                  datasaida: geraData(this.dados.nofdatasaida, 'YYYY-MM-DD HH:mm:ss'),
                  total: new Intl.NumberFormat("pt-BR", {style:'currency', currency:'BRL', minimumFractionDigits: 2 }).format(this.dados.noftotal),
                  transportador: this.dados.nofnometransportador,
                }
              }
              });
              console.log('envio', envio);
              if (envio == 'Erro')  {
                errorHandler(`Erro ao enviar a mensagem.`)
              } else {
                errorHandler('A mensagem foi enviada com sucesso.')
                this.dialog = false;
              }
            this.enviando = false;
          } else {
            errorHandler('Falha ao enviar a mensagem, sem conexão com a internet.')
            this.enviando = false; 
          }
        } catch(err) {
          console.log(err)
        }
      }
    },

    async $_validarDialog(){
      if (this.$$_stateDialog){
        if(this.produtor.nome !== '' && !this.produtor.numero) {
          errorHandler('Não foi possível encontrar o Whatsapp do produtor.')
          return false;
        }
        if(this.transportador.nome !== '' && !this.transportador.numero && !this.produtortransporte) {
          errorHandler('Não foi possível encontrar o Whatsapp do transportador.')
          return false;
        }
        if(Object.values(this.whatsapp) == 0) {
          errorHandler('Um Whatsapp não foi especificado.')
          return false
        }
      }else {
        if(this.produtor.nome !== '' && !this.produtor.email) {
          errorHandler('Não foi possível encontrar o email do produtor.')
          return false;
        }
        if(this.transportador.nome !== '' && !this.transportador.email) {
          errorHandler('Não foi possível encontrar o email do transportador.')
          return false;
        }
        if(Object.values(this.email.destinatario) == 0) {
          errorHandler('Um email não foi especificado.')
          return false
        }
      }
      return true
    },

    async $_changeRecipiente(value){
      if (value === 'produtor') {  

        if(this.$$_stateDialog) this.whatsapp.push(this.produtor.numero)
        else this.email.destinatario.push(this.produtor.email)
      
      } else {

        if(this.$$_stateDialog) this.whatsapp.push(this.transportador.numero)
        else this.email.destinatario.push(this.transportador.email)
      }
    },

    async $_setupDestinatario() {
      this.enviando = false;
      if (this.dados.nofidpessoa === this.dados.nofidtransportador) {
        this.produtortransporte = true;
        
        this.produtor = {
          nome: `${this.dados.nofnomepessoa}`,
          email: (await db.pessoa.where({ pesidpessoa: this.dados.nofidpessoa}).first()).pesemail,
          numero: (await db.pessoa.where({ pesidpessoa: this.dados.nofidpessoa}).first()).pestelefone
        }
        if (this.$$_stateDialog) this.whatsapp = [ this.produtor.numero ]
        else this.email.destinatario = [ this.produtor.email ]

      } else {
        this.produtor = {
          nome: `${this.dados.nofnomepessoa}`,
          email: (await db.pessoa.where({ pesidpessoa: this.dados.nofidpessoa}).first()).pesemail,
          numero: (await db.pessoa.where({ pesidpessoa: this.dados.nofidpessoa}).first()).pestelefone
        }
        this.transportador = {
          nome: `${this.dados.nofnometransportador}`,
          email: (await db.pessoa.where({ pesidpessoa: this.dados.nofidtransportador}).first()).pesemail,
          numero: (await db.pessoa.where({ pesidpessoa: this.dados.nofidtransportador}).first()).pestelefone
        }
        if(this.$$_stateDialog) this.whatsapp = [ this.produtor.numero, this.transportador.numero ] 
        else this.email.destinatario = [ this.produtor.email, this.transportador.email]
        }
      },

    async $_excluirDestinatario(param){
      if (param === 'produtor') {
        this.produtor.nome = ''
        if(this.$$_stateDialog) {
          const index = this.whatsapp.findIndex((item) => item === this.produtor.numero)
          this.whatsapp.splice(index, 1)
        } else {
          const index = this.email.destinatario.findIndex((item) => item === this.produtor.email)
          this.email.destinatario.splice(index, 1)
        }
      } else {
        this.transportador.nome = ''
        if(this.$$_stateDialog) {
          const index = this.whatsapp.findIndex((item) => item === this.transportador.numero)
          this.whatsapp.splice(index, 1)
        } else {
          const index = this.email.destinatario.findIndex((item) => item === this.transportador.email)
          this.email.destinatario.splice(index, 1)
        }
      }
      },
    },

  watch: {
    $$_stateDialog: {
      immediate: true,
      deep: true,
      handler() {
        console.log('STATE', this.stateDialog, this.$$_stateDialog, this.dados, this.produtor.numero, this.transportador.numero)
      }
    }
  },
  props: {
    dados: {
      type: Object,
      default: (() => {}),
    },
  },
};
</script>

<style scoped>
  .card {
    padding: 10px;
    margin-top: 15px;
  }

  .between {
    display: flex;
    justify-content: space-between;
  }

  .arquivos > * {
    margin: 5px 5px 5px 5px;
    font-weight: bold;
  }

  .subtitulo {
    padding-block: 15px;
  }

  .share {
    background-color: #eee;
    background-color: #4CAF50;
  }

  h4 {
    font-weight: 300;
  }

  .data {
    font-weight: 400;
  }

  .btn-emitir {
    width: 100%;
  }

  .btn-emitido {
    color: #4CAF50;
  }
  .erro {
    color: white;
    margin-bottom: 0.8em;
  }
  .v-chip {
    height: auto !important;
  }
  .erro .v-chip{
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    white-space: pre-wrap; 
    min-height: 32px;
  }

  .center {
    width: 100%;
    text-align: center;
  }
  .linha {
    display: flex;
    justify-content: space-around;
  }
  .content {
    padding: 15px;
  }
  .icone{
  color: white;
  }
  .botaoinferior{
    color: white;
  }
  .sheet {
    display: flex;
    justify-content: space-evenly;    
  }
  .border{
    border-top: 3px solid #4CAF50;
    background-color: #EEEEEE;
    margin: 0;
    padding: 0;
  }
  .fontwhite {
    color: white;
  }
</style>
