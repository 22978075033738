<template>
  <core-view class="flex-column" fill-height>
    <template>
      <div class="center">
        <form>
          <div class="linha">
            <btn-auto-complete
              label="Cultura"
              :dados="autocomplete.cultura"
              icon="mdi-chevron-down"
              v-model="visita.cultura"
              itemLabel="culdescricao"
              @change="$_toStorage('cultura', 'culdescricao')"
            >
            </btn-auto-complete>
          </div>

          <div class="linha">
            <btn-auto-complete
              label="Propriedade"
              icon="mdi-chevron-down"
              :dados="autocomplete.propriedade"
              v-model="visita.propriedade"
              itemLabel="pronome"
              @change="$_toStorage('propriedade', 'pronome')"
            >
            </btn-auto-complete>
          </div>

          <div class="linha">
            <btn-auto-complete
              label="Produtor"
              :redirect="rotaFiltros[0]"
              :dados="autocomplete.produtor"
              v-model="visita.produtor"
              itemLabel="pesnome"
              @change="$_toStorage('produtor', 'pesnome')"
            >
            </btn-auto-complete>
          </div>

          <div class="divinferior">
            <v-btn
              height="45"
              class="green botaoinferior"
              @click="$_salvaDados"
            >
              Salvar
            </v-btn>
          </div>
        </form>
      </div>
    </template>
  </core-view>
</template>

<script>
import CoreView from "@/components/core/view/Index.vue";
import BtnAutoComplete from "@/components/core/btn-auto-complete/Index.vue";
import db from "@/plugins/dexie";
import { SetupWebSocket, WsEnviaDados } from "@/utils/ws/websocket";
import { ROUTE_VISITA, ROUTE_OPERADOR } from "@/constants/ROUTES";
import { VISITA_STORAGE } from "@/constants/STORAGE";
import errorHandler from "@/utils/error-handler";
import moment from 'moment-timezone';
function getSessionStorageParse(key, defaultValue) {
  const retorno = sessionStorage.getItem(key) ?? defaultValue;
  try {
    return JSON.parse(retorno);
  } catch {
    return retorno;
  }
}

export default {
  name: "ViewVisitaTecnicaCadastro",
  components: {
    CoreView,
    BtnAutoComplete,
  },
  data() {
    return {
      rotaFiltros: [ROUTE_OPERADOR],
      campotexto: null,
      autocomplete: {
        cultura: null,
        propriedade: null,
        produtor: null,
      },
      visita: {
        cultura: null,
        propriedade: null,
        produtor: null,
        data: moment().format('YYYY-MM-DD'),
      },
    };
  },
  methods: {
    
    async $_salvaDados() {
      //Pega o idnuvem do usuario logado no momento
      const idnuvem = getSessionStorageParse("user:useidnuvem", null);

      let now = moment();
      now = now.subtract(3,'hours');

      const novoApontamento = {
        viscultura: getSessionStorageParse(VISITA_STORAGE.cultura, "").culdescricao,
        vispropriedade: getSessionStorageParse(VISITA_STORAGE.propriedade,'').pronome,
        visprodutor: getSessionStorageParse(VISITA_STORAGE.produtor,'').pesnome,
        visdatacriacao: new Date(),
      };

      const novoApontamentoNuvem = {
        vitidcultura: this.visita.cultura.culidcultura,
        vitidpropriedade: this.visita.propriedade.proidpropriedade,
        vitidprodutor: this.visita.produtor.pesidpessoa,
        vitidnuvem: "null",
        vitidusuario: idnuvem,
        vitdatacriacao: now.toISOString(),
      };

      console.log('nuvem', novoApontamentoNuvem)
      console.log('novoapontamento', novoApontamento)
      if (Object.values(novoApontamento).every((item) => item !== undefined)) {
        const validate = db.visita.add(novoApontamento);
        db.visitatecnica.add(novoApontamentoNuvem)
        WsEnviaDados({tipo: 'postVisitaTecnica', msg: novoApontamentoNuvem });

        ["propriedade", "produtor", "cultura", "data"].forEach((item) => {
          sessionStorage.removeItem(VISITA_STORAGE[item]);
        });
        if (validate) {
          this.$router.replace({
            ...this.$route,
            name: ROUTE_VISITA.name,
          });
        }
      } else {
        errorHandler("Alguns campos não foram preenchidos.");
      }
    },
    async $_findDb() {
      this.autocomplete.cultura = await db.cultura.toArray();
      this.autocomplete.propriedade = await db.propriedade.toArray();
      this.autocomplete.produtor = await db.pessoa.toArray();
      console.log('dexie', await db.visitatecnica.toArray());
      return null;
    },

    async $_setupCampos() {
      this.visita.cultura = getSessionStorageParse(VISITA_STORAGE.cultura,'');
      this.visita.produtor = getSessionStorageParse(VISITA_STORAGE.produtor,'');
      this.visita.propriedade = getSessionStorageParse(VISITA_STORAGE.propriedade,'');
    },
    async $_toStorage(tabela) {
      sessionStorage.setItem(
        VISITA_STORAGE[tabela],
        JSON.stringify(this.visita[tabela])
      );
    },
  },
  created() {
    this.$_findDb();
    this.$_setupCampos();
    SetupWebSocket();
  },
};
</script>

<style scoped>
.linha {
  display: flex;
}
.center {
  width: 100%;
  text-align: center;
}
.divinferior {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 5px;
  right: 0%;
}
.botaoinferior {
  color: white;
}

</style>