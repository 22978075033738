import db from "@/plugins/dexie";
import { WsEnviaDados } from "../../websocket";

export default {
    // Qnd WS estabelece/reestabelece conexão com o tipo 'novoid
    // ela envia os abastecimento que foram feitos offline e estavam salvos no dexie
    enviaLogExclusao: () => {
        const abastecimentos = db.logexclusao.where('loedatanuvem').equals('null').toArray();

        abastecimentos.then((tabela) => {
            tabela.forEach((loe) => {
            WsEnviaDados({ tipo: 'postLogExclusao', msg: loe });
            });
        });
    },
    retornoLogExclusao: ({ data }) => {
        const tabela = data.body;

        /* API DEVERIA RETORNAR UM ARRAY, DEIXEI ASSIM PRA NÃO MEXER NO CODIGO POR ENQUANTO */
        // tabela.forEach((item) => {
        //     db.logexclusao.where({ id: item.id }).delete(); 
        // });
        db.logexclusao.where({ id: tabela.item.id }).delete();
    },
}

