import geraData from "../geradata";

function modelVeiculo(item) {
  return {
    veiidveiculo: item.veiidveiculo,
    veiplaca: item.veiplaca,
    veimarca: item.veimarca,
    veitipo: item.veitipo,
    veidataatualizacao: item.veidataatualizacao,
    veiidnuvem: item.id,
    veitipomaquinario: item.veitipomaquinario,
  };
}

function modelProduto(item) {
  return {
    proidproduto: item.proidproduto,
    prodescricao: item.prodescricao,
    procodigo: item.procodigo,
    prodataatualizacao: item.prodataatualizacao,
    proidnuvem: item.id,
    proun: item.proun,
    proprecoa: item.proprecoa,
  };
}

function modelAtividade(item) {
  return {
    ataidatividadeabastecimento: item.ataidatividadeabastecimento,
    atadescricao: item.atadescricao,
    atadataatualizacao: item.atadataatualizacao,
    ataidnuvem: item.id,
    atacodigo: item.atacodigo,
  };
}

function modelOperacao(item) {
  return {
    opeidoperacao: item.opeidoperacao,
    opedescricao: item.opedescricao,
    opedataatualizacao: item.opedataatualizacao,
    opeidnuvem: item.id,
  };
}

function modelPropriedade(item) {
  return {
    proidpropriedade: item.proidpropriedade,
    pronome: item.pronome,
    prodataatualizacao: item.prodataatualizacao,
    proidnuvem: item.id,
  };
}

function modelLocalMovEstoque(item) {
  return {
    lmeidlocalmovimentacaoestoque: item.lmeidlocalmovimentacaoestoque,
    lmedescricao: item.lmedescricao,
    lmeidproduto: item.lmeidproduto,
    lmedataatualizacao: item.lmedataatualizacao,
    lmeidnuvem: item.id,
  };
}

function modelPessoa(item) {
  return {
    pesidpessoa: item.pesidpessoa,
    pesnome: item.pesnome,
    pesemail: item.pesemail,
    pescpf: item.pescpf,
    pesnomefantasia: item.pesnomefantasia,
    pesidnuvem: item.id,
    pesdataatualizacao: item.pesdataatualizacao,
    pespessoafuncionario: item.pespessoafuncionario,
    pestelefone: item.pestelefone,
  };
}

function modelLogExclusao(item) {
  return {
    loedataexclusao: geraData(item.loedataexclusao),
    loeidusuario: item.loeidusuario,
    loeidnuvem: item.loeidnuvem,
    loetabela: item.loetabela,
  };
}

function modelMovAbastecimento(item) {
  return {
    moatipooperacao: item.moatipooperacao,
    moaidveiculo: item.moaidveiculo,
    moaidpessoa: item.moaidpessoa,
    moatipoodometro: item.moatipoodometro,
    moaodometro: item.moaodometro,
    moaquvitidade: item.moaquvitidade,
    moaidprodutoorigem: item.moaidprodutoorigem,
    moaidpessoaorigem: item.moaidpessoaorigem,
    moaidpropriedade: item.moaidpropriedade,
    moaidatividade: item.moaidatividade,
    moaobservacao: item.moaobservacao,
    moaidlocalarmazenamentoorigem: item.moaidlocalarmazenamentoorigem,
    moadatacriacao: item.moadatacriacao,
    moaidnuvem: item.id,
    moaidusuario: item.moaidusuario,
  };
}
function modelLancamentoAtividade(item) {
  return {
    laaidlancamentoatividade: item.laaidlancamentoatividade,
    laaidatividade: item.laaidatividade,
    laaidoperador: item.laaidoperador,
    laaidpropriedade: item.laaidpropriedade,
    laadatacriacao: geraData(item.laadatacriacao),
    laadataatualizacao: item.laadataatualizacao,
    laaidnuvem: item.id,
    laaidusuario: sessionStorage.getItem('user:useidnuvem'),
    laadatainicio: geraData(item.laadatainicio),
    laadatatermino: geraData(item.laadatatermino),
    laaidtalhao: item.laaidtalhao,
    laaobservacao: item.laaobservacao,
    laadatanuvem: item.laadataatualizacao,
  }
}
function modelCultura(item) {
  return {
    culidcultura: item.culidcultura,
    culdescricao: item.culdescricao,
    culidpropriedade: item.culidpropriedade,
    culdataatualizacao: item.culdataatualizacao,
    culidnuvem: item.id
  }
}
function modelVisitaTecnica(item) {
  return {
    vitidpropriedade: item.vitidpropriedade,
    vitidprodutor: item.vitidprodutor,
    vitidcultura: item.vitidcultura,
    vitdatacriacao: item.vitdatacriacao,
    vitidnuvem: item.id,
    vitidusuario: item.vitidusuario
  }
}
function modelLancamentoVisitaTecnica(item) {
  return {
    lvtidlancamentovisitatecnica: item.lvtidlancamentovisitatecnica,
    lvtidvisita: item.lvtidvisita,
    lvtdatalancamento: geraData(item.lvtdatalancamento),
    lvtnome: item.lvtnome,
    lvtdescricao: item.lvtdescricao,
    lvtobservacao: item.lvtobservacao,
    lvtidtalhao: item.lvtidtalhao,
    lvtdataanterior: geraData(item.lvtdataanterior),
    lvtdatanuvem: item.lvtdatanuvem,
    lvtidusuario: sessionStorage.getItem('user:useidnuvem'),
    lvtaguardandomidia: item.lvtaguardandomidia,
    lvtdataatualizacao: geraData(item.lvtdataatualizacao),
    lvtidnuvem: item.id,
  };
}
function modelNaturezaOperacao(item) {
  return {
    naoidnuvem: item.id,
    naoidnaturezaoperacao: item.naoidnaturezaoperacao,
    naodescricao: item.naodescricao,
    naodataatualizacao: item.naodataatualizacao
  }
}

function modelNotaFiscal(item) {
  return {
    nofidnaturezaoperacao: item.nofidnaturezaoperacao,
    nofidpessoa: item.nofidpessoa,
    nofidproduto: item.nofidproduto,
    nofun: item.nofun,
    nofquantidade: item.nofquantidade,
    nofvalorunitario: item.nofvalorunitario,
    noftotal: item.noftotal,
    nofidtransportador: item.nofidtransportador,
    noftipofrete: item.noftipofrete,
    nofidveiculo: item.nofidveiculo,
    nofespecie: item.nofespecie,
    nofnronota: item.nofnronota,
    nofserie: item.nofserie,
    nofnomepessoa: item.nofnomepessoa,
    nofplaca: item.nofplaca,
    nofdataemissao: item.nofdataemissao,
    nofcodigoretornanfesefaz: item.nofcodigoretornanfesefaz,
    nofmensagemretornanfesefaz: item.nofmensagemretornanfesefaz,
    nofidnuvem: item.id,
    nofdataatualizacao: item.nofdataatualizacao,
    nofidusuario: item.nofidusuario,
    nofdatacriacao: item.nofdatacriacao,
    nofdatasaida: item.nofdatasaida
  }
}

function modelNotaFiscalitem(item) {
  return {
    nfiidproduto: item.nfiidproduto,
    nfiquantidade: item.nfiquantidade,
    nfivalorunitario: item.nfivalorunitario,
    nfivalortotal: item.nfivalortotal,
    nfiun: item.nfiun,
    nfidnuvemnotafiscal: item.nfidnuvemnotafiscal,
    nfiidnuvem: item.nfiidnuvem,
    nfidescricao: item.nfidescricao,
    nfidataatualizacao: item.nfidataatualizacao,
    nfidatanuvem: item.nfidatanuvem,
  }
}

function modelTalhao(item) {
  return {
    talidtalhao: item.talidtalhao,
    talidnuvem: item.id,
    taldescricao:  item.taldescricao,
    taldataatualizacao: item.taldataatualizacao,
    talcodigo: item.talcodigo,
  }
}

// ESLint prefere default, mas não permite exportar variavel como default;

export const WSModel = {
  veiculo: modelVeiculo,
  produto: modelProduto,
  atividadeabastecimento: modelAtividade,
  operacao: modelOperacao,
  propriedade: modelPropriedade,
  localmovimentacaoestoque: modelLocalMovEstoque,
  pessoa: modelPessoa,
  logexclusao: modelLogExclusao,
  movabastecimento: modelMovAbastecimento,
  lancamentoatividade: modelLancamentoAtividade,
  cultura: modelCultura,
  visitatecnica: modelVisitaTecnica,
  lancamentovisitatecnica: modelLancamentoVisitaTecnica,
  naturezaoperacao: modelNaturezaOperacao,
  notafiscal: modelNotaFiscal,
  notafiscalitem: modelNotaFiscalitem,
  talhao: modelTalhao
};
