import db from "@/plugins/dexie";
import { WsEnviaDados } from "../../websocket";
import geraDataV2 from "@/utils/geraDataV2";
import geraData from "@/utils/geradata";

export default {
    enviaLancamentoAtividade: () => {
        const lancamentos = db.lancamentoatividade.where('laaidnuvem').equals('null').toArray();

        lancamentos.then((tabela) => {
            tabela.forEach((laa) => {
                WsEnviaDados({tipo: 'postLancamentoAtividade', msg: laa})
            });
        });
    },
    retornoLancamentoAtividade: ({ data }) => {
        const item = data.body;
        // query pela DataCriacao para fazer um handshake entre a WS para atribuir o idnuvem no lancamento
        const queryPromise = db.lancamentoatividade.where('laadatacriacao').equals(geraDataV2({
            date: item.laadatacriacao,
            add: 3,
            ignoreDev: true,
        })).toArray();
        queryPromise.then((tabela) => {
            tabela.forEach((linha) => {
                const newObj = {laaidnuvem: item.id, laadatanuvem: geraData()};
                db.lancamentoatividade.update(linha.id, newObj);
            })
        })
    },
}