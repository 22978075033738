import connection from './core/connection';
import crud from './core/crud';
import geral from './core/geral';

import atividade from './tabelas/atividade';
import abastecimento from './tabelas/abastecimento.';
import notafiscal from './tabelas/notafiscal';
import notafiscalitem from './tabelas/notafiscalitem';
import visitatecnica from './tabelas/visitatecnica';
import lancamentovisitatecnica from './tabelas/lancamentovisitatecnica';
import logexclusao from './tabelas/logexclusao';

export default {
    ...connection,
    ...crud,
    ...geral,
    //
    ...atividade,
    ...abastecimento,
    ...notafiscal,
    ...visitatecnica,
    ...lancamentovisitatecnica,
    ...logexclusao,
    ...notafiscalitem,
    //
    /* Todos os metodos abaixo executam nos ciclos do websocket */
    novoid(data) {
        this.enviaLancamentoAtividade();
        this.enviaAbastecimento();
        this.enviaVisitaTecnica();
        this.enviaNotaFiscal();
        this.enviaNotaFiscalItem();
        this.enviaDataAtt(data);
        this.sincronizaMidias(data);
    }
}