<template>
    <v-dialog
        persistent
        v-model="dialog"
        width="500"
        @input="v => v || $_closeDialog()"
        >
      <template v-slot:activator="{ on, attrs }" >
        <img :src="src" v-bind="attrs " v-bind:style='imgStyle'
        v-on="on" v-on:click="$_openDialog()"/>
      </template>
      <v-card v-if="card">
        <v-card-title v-bind:class='classe' id='card-superior'>
            <div id='label'>
              {{titulo}}
            </div>
            <v-icon large id='icone'>
              {{icone}}
            </v-icon>
          </v-card-title>
        <v-card-text>
          {{mensagem}}
        </v-card-text>
         <div id='center'>
          <div id='nomearquivo-wrapper' v-if="!alterSetup">
              <v-select 
              label="Talhão"
              v-if="btnSetup"
              :readonly="enviando"
              outlined
              :items="autocomplete.talhao"
              item-text="taldescricao"
              item-value="talidtalhao"
              @change="$_setUltimoTalhao($event)"
              v-model="talhao"
              >
              </v-select>
              <v-text-field
              v-if="btnSetup"
              autocomplete="off"
              :readonly="enviando"
              outlined
              counter="25"
              maxlength="25"
              ref='dialogAtual'
              id='nomearquivo-edit'
              class="nomeedit"
              type=text
              label="Nome"
              v-model='edtTxt'
              ></v-text-field>

              <v-textarea
              v-if="btnSetup"
              :readonly="enviando"
              outlined
              counter="200"
              maxlength="200"
              rows='2'
              class="descricao"
              type=text
              label="Descrição"
              v-model='descricao'
              ></v-textarea>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="btnSetup" class="botaoenviar">
          <v-btn v-if="!enviando"  class='black--text'
            text
            @click="btnSetup=true;dialog=false;$_closeDialog();">
            Cancelar
          </v-btn>
          <v-btn v-if="!enviando" class='btn white--text'
            color="blue-grey"
            @click="$_Upload()" >
            Enviar
          </v-btn>
          <div v-else class="flex">
            <p>
              Enviando Arquivo
            </p>
            <v-progress-circular
              indeterminate
              color="green"
              class="circular"
            ></v-progress-circular>
          </div>
          </div>
          <!-- <div v-else > -->
            <!-- <v-btn class='black--text' v-if="exibeBotaoReenviar"
              @click="dialog=false;$_ConfirmDownload()">
              Salvar
            </v-btn> -->
            <v-btn v-else class='white--text'
              color="blue-grey"
              @click="dialog=false;$_ConfirmDelete()">
              Voltar
            </v-btn>
          <!-- </div> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

// import api from '@/services/api-recebimentoweb';

import errorHandler from '@/utils/error-handler';

import moment from 'moment-timezone';
import { VISITA_INFO_STORAGE, VISITA_STORAGE } from '@/constants/STORAGE';
import db from '@/plugins/dexie'
import { ROUTE_VISITA_INFO } from '@/constants/ROUTES';
import geraData from '@/utils/geradata';
import { mapActions, mapGetters } from 'vuex';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  props: {
    src: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      dialog: false,
      snackbar: false,
      text: null,
      imageURL: null,
      id: this.$route.params.id,
      imgStyle: null,
      card: true,
      edtTxt: this.$route.params.valor,

      // Customização da caixa de texto;
      alterSetup: false,
      btnSetup: true,
      classe: null,
      icone: null,
      mensagem: null,
      titulo: null,
      enviando: false,
      exibeBotaoReenviar: false,

      descricao: null,
      talhao: null,

      autocomplete: {
        talhao: null,
      }
    };
  },

  methods: {

    ...mapActions({
      $_setUltimoTalhao: 'visitatecnica/setUltimoTalhao',
    }),

    async ConfirmDownload() {
      errorHandler('Um nome de documento é obrigatório');
    },

    async $_DownloadAll() {
      this.$emit('downloadall');
      this.$_closeDialog();
    },

    async $_ConfirmDelete() {
      if (this.titulo === 'Sucesso') {
        this.$emit('remove');
      }
      this.$_closeDialog();
    },

    async $_closeDialog() {
      this.descricao = null;
      this.imgStyle = null;

      if(this.alterSetup){
        this.$router.replace({
        ...this.$route,
        name: ROUTE_VISITA_INFO.name,
      })
      }
    },

    async $_openDialog() {
      this.imgStyle = 'border: 6px solid hsl(210,70%,50%)';
      setTimeout(() => {
        this.$refs.dialogAtual.focus();
      }, 0);
      this.Alterar();
    },

    async Alterar(){
      const idimg = parseInt(getSessionStorage(VISITA_INFO_STORAGE.idinfo))
      if(idimg) {
        this.newDialog('Alteracao');
        this.alterSetup = true;
        console.log('altersetup', this.alterSetup)
      } else {
        this.newDialog()
      }
    },

    async $_Upload() {
      const idimg = parseInt(getSessionStorage(VISITA_INFO_STORAGE.idinfo))
      if (!this.edtTxt && !idimg) {
        console.log(this.edtTxt);
        errorHandler('Um nome de documento é obrigatório');
        return;
      }
      const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n) {
          u8arr[n - 1] = bstr.charCodeAt(n - 1);
          n -= 1; // to make eslint happy
        }
        return new File([u8arr], filename, { type: mime });
      };

      
      //const formData = new FormData();
      
      try {
        this.file = await dataURLtoFile(this.src);

        //const date = await this.preciseDate(new Date());
        console.log(this.file)
        const nome = `${this.edtTxt}`;
        //formData.append('image', this.file, nome);

        // sessionStorage.setItem(VISITA_INFO_STORAGE.imagem, this.src);
        if(this.edtTxt && this.descricao != null) {
          sessionStorage.setItem(VISITA_INFO_STORAGE.titulo, nome);
          sessionStorage.setItem(VISITA_INFO_STORAGE.observacao, this.descricao);
        }
      } catch (err) {
        this.newDialog('Erro', err);
        return;
      }
      //console.log('formdata', formData);
      const novaimagem = {
        lvtimagem: this.src, //getSessionStorage(VISITA_INFO_STORAGE.imagem, ''),
        lvtnome: getSessionStorage(VISITA_INFO_STORAGE.titulo, ''),
        lvtdescricao: getSessionStorage(VISITA_INFO_STORAGE.observacao,''),
        lvtidvisita: getSessionStorage(VISITA_STORAGE.idvisita, ''),
        lvtdatalancamento: geraData(),
        lvtdataatualizacao: geraData(),
        lvtaguardandomidia: 'N',
        lvtdatanuvem: 'null',
        lvtidusuario: sessionStorage.getItem('user:useidnuvem', null),
        lvtidtalhao: this.talhao,
      }

      this.enviando = true;

      // Se tiver algum ID de uma imagem, faz uma alteração nela

      if (idimg){
        const imganterior = (await db.lancamentovisitatecnica.where({ id: idimg }).first()).lvtdata;
        db.lancamentovisitatecnica.where({ id: idimg}).modify({
          lvtimagem: this.src,
          lvtdataanterior: imganterior,
        }).then(() => {
          this.enviando = false;
          this.newDialog('Sucesso');
        }).catch((err) => {
          this.enviando = false
          this.newDialog('Erro', err);
        });
        return;
      }

      db.lancamentovisitatecnica.add(novaimagem).then(() => {
        this.enviando = false;
        this.newDialog('Sucesso');
      }).catch((err) => {
        let errorMessage = err;
        if (!err.response) {
          // network error
          errorMessage = `Falha de conexão, verifique a estabilidade da rede e tente novamente. Uma
          cópia do arquivo foi salva na galeria`;
        }
        this.enviando = false;
        this.newDialog('Erro', errorMessage);
      }).finally(() => {
        console.log('finally')
        this.$_salvaArquivoGaleria();
        });
    },

    async $_salvaArquivoGaleria() {
      const el = document.createElement('a');
      el.href = this.$props.src;
      el.download = `${this.edtTxt}_${moment().format('DD_MM_YYYY')}`;
      document.body.appendChild(el);
      el.click();
      document.body.removeChild(el);
    },

    // maxContentLength: Infinity,
    // maxBodyLength: Infinity,

/*     stopPipe() {
      try {
        this.edtTxt = this.edtTxt.split('/').join('');
        this.edtTxt = this.edtTxt.split('|').join('');
        this.edtTxt = this.edtTxt.split(' ').join('');
        this.edtTxt = this.edtTxt.split('\\').join('');
      } catch {
        console.log('Input vazio');
      }
    }, */

    async preciseDate(date) {
      const dateArray = [
        (`00${date.getDate()}`).slice(-2),
        (`00${date.getMonth() + 1}`).slice(-2),
        date.getFullYear(),
        '_',
        (`00${date.getHours()}`).slice(-2),
        (`00${date.getMinutes()}`).slice(-2),
        (`00${date.getSeconds()}`).slice(-2),
        '_',
        (`000${date.getMilliseconds()}`).slice(-3),
      ];
      return dateArray.join();
    },

    async newDialog(param, err) {
      // this.exibeBotaoReenviar = false;
      switch (param) {
        case 'Erro':
          // this.exibeBotaoReenviar = true;
          this.MontaDialog([
            'Erro',
            'mdi-alert-circle',
            'headline red white--text',
            err,
          ]);
          this.btnSetup = false;
          break;
        case 'Sucesso':
          this.MontaDialog([
            'Sucesso',
            'mdi-checkbox-marked-circle-outline',
            'headline green white--text',
            'A imagem foi salva com sucesso!',
          ]);
          this.btnSetup = false;
          break;
        case 'Alteracao':
          this.MontaDialog([
            'Alteração',
            'mdi-image-edit-outline',
            'headline green white--text',
            'Realmente deseja alterar esta imagem?',
          ]);
          this.btnSetup = true;
          break;
        default:
          this.MontaDialog([
            'Salvar Imagem',
            'mdi-content-save',
            'headline green white--text',
            'Digite um nome e a descrição da observação.',
          ]);
          this.btnSetup = true;
          break;
      }
    },

    async MontaDialog(params) {
      [this.titulo, this.icone, this.classe, this.mensagem, this.btnSetup] = params;
    },

    async $_setupAutoComplete() {
      const dadosTalhao = db.talhao.toArray();
      dadosTalhao.then((dados) => {
        this.autocomplete.talhao = dados;
        this.talhao = this.$$_ultimoTalhao;
      })
    },
  },

  computed: {
    ...mapGetters({
      $$_ultimoTalhao: 'visitatecnica/ultimoTalhao',
    })
  },

  created() {
    this.$_setupAutoComplete();
  }
};

</script>

<style scoped>
img {
    height: 100%;
}

#aceitar {
  float: left;
  position: relative;
}

#card-superior {
  display: flex;
  justify-content: space-between;
  margin-top: -5%;
}

#icone {
  color: white;
}

#label {
  margin-top: 5%;
}

#nomearquivo-edit input{
  font-size: 1.9em;
}
.nomeedit{
  margin-bottom: -10px;
}
.descricao {
  margin-bottom: 5px;
}
#nomearquivo-wrapper {
  width: 80%;
  height: 20%;
}

#center {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: -10%;
}

.flex {
  display: flex;
  margin: 0;
}

.flex p {
  margin-right: 20px;
}

.flex .circular {
  position: relative;
  top: -4px;
}

.botaoenviar{
  margin-top: 8%;
}
</style>
