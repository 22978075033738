<template>
    <core-view class="flex-column" fill-height>
        <div>
          <dialog-troca-senha>
          </dialog-troca-senha>
        </div>
    </core-view>
</template>

<script>
import { mapActions } from 'vuex';

// import errorHandler from '@/utils/error-handler';

import CoreView from '@/components/core/view/Index.vue';

import DialogTrocaSenha from '@/components/core/dialog-troca-senha/Index.vue';

import { SetupWebSocket } from '../../utils/ws/websocket';

import db from '@/plugins/dexie';

export default {
  name: 'ViewHome',

  components: {
    CoreView,
    DialogTrocaSenha,
  },

  data() {
    return {
      formatado: [],
      wsConnection: null,
      websocket: {
        connectionId: null,
        primeiraconexao: true,
        conectado: false,
      },
      online: false,
    };
  },

  methods: {
    ...mapActions({
      $_recebimentosFind: 'recebimento/find',
      $_findFila: 'fila/find',
      $_updateWebPushUser: 'webpush/updateUserId',
    }),

    getSessionStorage(key, defaultValue) {
      return sessionStorage.getItem(key) ?? defaultValue;
    },

    async SetupWebPushToken() {
      const token = sessionStorage.getItem('auth:token');
      const item = await db.worker.get({ id: 1 });
      setTimeout(() => {
        db.worker.put({
          ...item,
          id: 1,
          wortoken: token,
        })
      }, 250);
      const key = (await db.worker.get({id: 1}))?.worpushkey;
      console.log(token, 'sub');
    if (key) {
        const resposta = await this.$_updateWebPushUser({
          key,
          userid: (JSON.parse(token)).userId,
        })
        console.log(resposta);
    } else {
        console.log('No active subscription found.');
        return null;
    }
    },
  },

  created() {
    SetupWebSocket();
    this.SetupWebPushToken();
  },
};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }

  .center {
    width: 100%;
    text-align: center;
  }

</style>
