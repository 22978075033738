export const nomesTabelas = [
  {
    tabela: 'localmovimentacaoestoque',
    prefixo: 'lme',
  },
  {
    tabela: 'veiculo',
    prefixo: 'vei',
  },
  {
    tabela: 'operacao',
    prefixo: 'ope',
  },
  {
    tabela: 'atividadeabastecimento',
    prefixo: 'ata',
  },
  {
    tabela: 'pessoa',
    prefixo: 'pes',
  },
  {
    tabela: 'propriedade',
    prefixo: 'pro',
  },
  {
    tabela: 'produto',
    prefixo: 'pro',
  },
  {
    tabela: 'movimentacaoabastecimento',
    prefixo: 'moa',
    userOnly: true,
  },
  {
    tabela: 'logexclusao',
    prefixo: 'loe',
  },
  {
    tabela: 'lancamentoatividade',
    prefixo: 'laa',
    userOnly: true,
  },
  {
    tabela: 'lancamentovisitatecnica',
    prefixo: 'lvt',
    userOnly: true,
  },
  {
    tabela: 'cultura',
    prefixo: 'cul',
  },
  {
    tabela: 'visitatecnica',
    prefixo: 'vit',
    userOnly: true,
  },
  {
    tabela: 'naturezaoperacao',
    prefixo: 'nao',
  },
  {
    tabela: 'notafiscal',
    prefixo: 'nof',
    userOnly: true,
  },
  {
    tabela: 'notafiscalitem',
    prefixo: 'nfi',
    userOnly: true,
  },
  {
    tabela: 'talhao',
    prefixo: 'tal',
  }
];

export const prefixos = {}

export function setupModel() {
  for (const item of nomesTabelas) {
    prefixos[item.tabela] = { ...item };
  }
}
