export default {
    namespaced: true,

    state: {
        // cache para urls blob: (evitar memory leaks)
        blobUrls: {},

        // ultimo talhao selecionado nos v-selects
        ultimoTalhao: null,
    },

    getters: {
        blobUrls: (state) => (key) => {
            return state.blobUrls[key];
        },
        ultimoTalhao: (state) => {
            return state.ultimoTalhao;
        }
    },

    actions: {
        // SETTERS
        async setBlobUrL({ commit }, data) {
            commit('SET_BLOB_URLS', data);
        },
        async setUltimoTalhao({ commit }, data) {
            commit('SET_ULTIMO_TALHAO', data);
        },
        //
    },

    mutations: {
        SET_BLOB_URLS(state, data) {
            if (!data.id) {
                console.error('SET_DATA_URLS: data não possui ID');
                return;
            } 
            state.blobUrls[data.id] = data.url;
        },
        SET_ULTIMO_TALHAO(state, data) {
            try {
                state.ultimoTalhao = data;
            } catch (err) {
                console.error(`SET_ULTIMO_TALHAO: ${err}`);
            }
        },
    }
}