<template>
  <div>
    <div class="controles__reproduzir">
        <b :style="{color: corEstado}">{{estadoGravacao}}</b>
    </div>
    <br/>
    <div class="controles__reproduzir">
        <audio 
        v-if="audioUrl"
        :src="audioUrl"
        controls>
        </audio>
    </div>
    <br/>
    <div class="controles__gravacao" v-if="!hold">
        <v-btn
        @click="$_deleteRecording"
        color="red"
        v-if="isRecording"
        fab>
            <v-icon color="white">
                mdi-close
            </v-icon>
        </v-btn>
        <v-btn
        @click="$_startRecording"
        v-if="!audioUrl && !isRecording"
        fab>
            <v-icon>
                mdi-microphone
            </v-icon>
        </v-btn>
        <v-btn
        @click="$_resumeRecording"
        v-else-if="isPaused"
        fab>
            <v-icon>
                mdi-play
            </v-icon>
        </v-btn>
        <v-btn
        @click="$_pauseRecording"
        v-if="isRecording && !isPaused"
        fab>
            <v-icon>
                mdi-pause
            </v-icon>
        </v-btn>
        <v-btn
        @click="$_stopRecording"
        v-if="isRecording"
        color="green"
        fab>
            <v-icon color="white">
                mdi-check
            </v-icon>
        </v-btn>
    </div>
    <div 
    class="controles__gravacao"
    v-if="hold">
      <v-btn
      @click="$_holdToRecord"
      fab>
          <v-icon>
              mdi-microphone
          </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "AudioRecorder",

  props: {
    hold: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      mediaRecorder: null,
      audioChunks: [],
      audioUrl: null,
      isRecording: false,
      isPaused: false,

      estadoGravacao: 'Clique no icone para começar',
      corEstado: '#999',

      delete: false,
    };
  },
  methods: {
    async $_startRecording() {
      try {
        this.delete = false;
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);
        this.audioChunks = [];
        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.audioChunks.push(event.data);
          }
        };
        this.mediaRecorder.onstop = () => {
          if (this.delete) {
            return;
          }
          const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
          this.audioUrl = URL.createObjectURL(audioBlob);
          setTimeout(() => {
            this.$emit('stop', {
              blob: audioBlob,
              url: this.audioUrl,
            });
          })
        };
        this.mediaRecorder.start();
        this.isRecording = true;
        this.isPaused = false;
        this.estadoGravacao = 'Gravando...';
        this.corEstado = 'blue';
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    },
    $_stopRecording() {
      if (this.mediaRecorder && this.isRecording) {
        this.mediaRecorder.stop();
        this.isRecording = false;
        this.isPaused = false;
        this.estadoGravacao = 'Gravação Finalizada';
        this.corEstado = 'green';
      }
    },
    $_pauseRecording() {
      if (this.mediaRecorder && this.isRecording && !this.isPaused) {
        this.mediaRecorder.pause();
        this.isPaused = true;
        this.estadoGravacao = 'Gravação Pausada';
        this.corEstado = 'red';
      }
    },
    $_resumeRecording() {
      if (this.mediaRecorder && this.isRecording && this.isPaused) {
        this.mediaRecorder.resume();
        this.isPaused = false;
        this.estadoGravacao = 'Gravando...';
        this.corEstado = 'blue';
      }
    },
    $_deleteRecording() {
      this.delete = true;
      if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
        this.mediaRecorder.stop();
      }
      this.audioUrl = null;
      this.audioChunks = [];
      this.isRecording = false;
      this.isPaused = false;
      this.estadoGravacao = 'Clique no icone para começar';
      this.corEstado = '#999';
    },
    
    $_holdToRecord(event) {
      event.target.addEventListener("mouseup", this.$_stopRecording, { once: true });
      event.target.addEventListener("touchend", this.$_stopRecording, { once: true });

      this.$_startRecording();
    }
  }
};
</script>

<style scoped>
    .controles__gravacao {
        display: flex;
        justify-content: space-around;
    }
    .controles__reproduzir {
        display: flex;
        justify-content: center;
    }
</style>
