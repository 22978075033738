import apiveiculoweb from '@/services/api-recebimentoweb';
import errorHandler from '@/utils/error-handler';

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {
    async updateComWebSocket(_, { body }) {
      const { data } = await apiveiculoweb.put(`/notafiscal/websocket/${body.id}`, body);
      return data;
    },

    async enviarEmail(_, {body}) {
        const enviar = await apiveiculoweb.post('/notafiscal/emitir/email', body).catch((err) => {
          console.log(err);
          return 'Erro'
        })
        return enviar
    },
    
    async enviarWhatsapp(_, {body}) {
      const enviar = await apiveiculoweb.post('/notafiscal/emitir/whatsapp', body).catch((err) => {
        console.log(err);
        return 'Erro'
      })
      return enviar
    },

    async verificarEmissao(_, { body }) {
      const { data } = await apiveiculoweb.put('/notafiscal/update', body);
      return data;
    },

    async findFiles(_, { params }) {
      try {
        const { data }  = await apiveiculoweb.get(`/notafiscal/emitir/${params.serie}/${params.nro}`, params);
        const baseUrl = (await apiveiculoweb.get()).config.baseURL
        let files = [];
        for (const obj of data) {
          const blob = await (await fetch(`${baseUrl}/nfeupload/uploads/${params.nro}-${params.serie}-NFE/${obj}`)).blob()
          files.push({blob, name: obj})
        }
        return files
      } catch (err) {
          errorHandler('Falha ao baixar a nota fiscal, verifique a sua conexão com a internet.');
          console.log(err)
      }
    }
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
