<template>
  <div v-if="!loading" class="my-auto">
    <div class="semapontamento" v-if="valores.length === 0">
      <br/>
      Não foi encontrado nenhuma visita técnica.
    </div>
    <div class="divnovo">
      <v-btn  height="45" color="green botaoinferior" @click="$_lancarVisita">
        Novo
      </v-btn>
    </div>
    <div>
        <div v-for="item in valores" :key="item.id" ref="lista">
            <app-card-visita-tecnica :value="item" class="card" @innerClick="$_onClickCard">
            </app-card-visita-tecnica>
        </div>
    </div>
  </div>
</template>

<script>
import { VISITA_STORAGE } from '@/constants/STORAGE';
import { ROUTE_VISITA_CADASTRO, ROUTE_VISITA_INFO } from '@/constants/ROUTES' 
import { SetupWebSocket } from '@/utils/ws/websocket';
import { VISITA_QUERIES } from '@/constants/QUERY_FILTERS';
import { getQueryValue, setQueryValue } from '@/services/query-string';
import deepEquals from '@/utils/deep-equals';
import AppCardVisitaTecnica from '@/components/app/card/visita/Index.vue'
import db from '@/plugins/dexie'

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}


export default {
  name: 'ViewVisitaTecnica',
  components: {
    AppCardVisitaTecnica,
  },
  data() {
    return {
      valores: [],
      loading: true,
      filters: {
        createdFrom: getSessionStorage(VISITA_STORAGE.createdFrom, null),
        createdBefore: getSessionStorage(VISITA_STORAGE.createdBefore, null),
      },
    }
  },
  methods: {
    async $_load() {
      console.log('load');
      this.loading = true;
      try {
        this.valores = await db.visita.where('visdatacriacao').between(
          new Date(this.filters.createdFrom), new Date(`${this.filters.createdBefore}T23:59:00`)
        ).toArray();
        
        console.log("Valores: ", this.valores)
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
      return null;
    },
    
    async $_onClickCard(idCard){
      sessionStorage.setItem(VISITA_STORAGE.idvisita, idCard);
      
      this.$router.replace({
        ...this.$route,
        name: ROUTE_VISITA_INFO.name,
      })
    },
    $_lancarVisita() {
      sessionStorage.setItem(VISITA_STORAGE.idvisita, '0');
      ['propriedade','produto','cultura','data','idvisita'].forEach((item) => {
        sessionStorage.removeItem(VISITA_STORAGE[item]);
      });
      this.$router.replace({
        ...this.$route,
        name: ROUTE_VISITA_CADASTRO.name,
      });
    },
  },
    watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {

        const filters = {
          createdFrom: getQueryValue(VISITA_QUERIES.createdFrom, this.filters.createdFrom),
          createdBefore: getQueryValue(
            VISITA_QUERIES.createdBefore,
            this.filters.createdBefore,
          ),
        };

        if (!deepEquals(this.filters, filters)) {
          this.filters.createdFrom = filters.createdFrom;
          this.filters.createdBefore = filters.createdBefore;
          this.$_load();   
        }
      },
    },

    options: {
      deep: true,
      handler() {
        setQueryValue(VISITA_QUERIES.createdFrom, this.filters.createdFrom);
        setQueryValue(VISITA_QUERIES.createdBefore, this.filters.createdBefore);
      },
    },
  },
  created(){
    this.$_load();
    SetupWebSocket();
  }
}
</script>

<style scoped>
    .semapontamento {
      text-align: center;
    }
    .botaoinferior {
      color:white;
    }
    .divnovo {
      text-align: center;
      width: 100%;
      margin-top: 20px;
    }

    .card {
    padding: 3px;
    margin: 15px 10px 0px 10px
    }

</style>,