import db from "@/plugins/dexie";
import geraData from "@/utils/geradata";
import api from '@/services/api-recebimentoweb';

export default {
    retornoLancamentoVisitaTecnica: ({ data }) => {
        const item = data.body;
        // query pela DataCriacao para fazer um handshake entre a WS para atribuir o idnuvem no lancamento
        const queryPromise = db.lancamentovisitatecnica.where('lvtdatalancamento').equals(geraData(item.lvtdatalancamento)).toArray();
        queryPromise.then((tabela) => {
            console.log(tabela, 'tabela');
            tabela.forEach((linha) => {
                db.lancamentovisitatecnica.update(linha.id, {lvtidnuvem: item.id, lvtdatanuvem: geraData()});
            })
        })
    },
    envioComMidia: async ({dados, update}) => {
        // eslint-disable-next-line no-unused-vars
        const { lvtvideo, lvtimagem, lvtaudio, id, ...novosDados } = dados;
        const file = lvtvideo || lvtimagem || lvtaudio;
        if (!file) {
            return dados;
        } 
    
        const formData = new FormData();
        formData.append('data', JSON.stringify(novosDados));
    
        if (lvtimagem) {
            const dataURLtoFile = (dataurl, filename) => {
                const arr = dataurl.split(',');
                const mime = arr[0].match(/:(.*?);/)[1];
                const bstr = atob(arr[1]);
                let n = bstr.length;
                const u8arr = new Uint8Array(n);
                while (n) {
                u8arr[n - 1] = bstr.charCodeAt(n - 1);
                    n -= 1; // to make eslint happy
                }
                    return new File([u8arr], filename, { type: mime });
                };
            const file = await dataURLtoFile(lvtimagem);
            if (file instanceof File || file instanceof Blob) {
                formData.append('file', file);
            }
    
            if (update) {
                api.put(`/lancamentovisitatecnica/imagem`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        db.lancamentovisitatecnica.where({id: dados.id}).modify({
                            lvtdatanuvem: geraData(),
                        });
                    }
                })
            }
            api.post(`/lancamentovisitatecnica/imagem`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                if (response.status === 201) {
                    db.lancamentovisitatecnica.where({id: dados.id}).modify({
                        lvtdatanuvem: geraData(),
                        lvtidnuvem: response.data.data.id,
                    });
                }
            })
            .catch(error => console.error(error));
        } else if (lvtvideo) {  
            if (file instanceof File || file instanceof Blob) {
                formData.append('file', file);
            }
            
            if (update) {
                api.put(`/lancamentovisitatecnica/video`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            }
            api.post(`/lancamentovisitatecnica/video`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                if (response.status === 201) {
                    db.lancamentovisitatecnica.where({id: dados.id}).modify({
                        lvtdatanuvem: geraData(),
                        lvtidnuvem: response.data.data.id,
                    });
                }
            })
            .catch(error => console.error(error));
        } else {
            if (file instanceof File || file instanceof Blob) {
                formData.append('file', file);
            }
            
            if (update) {
                api.put(`/lancamentovisitatecnica/audio`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            }
            api.post(`/lancamentovisitatecnica/audio`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                if (response.status === 201) {
                    db.lancamentovisitatecnica.where({id: dados.id}).modify({
                        lvtdatanuvem: geraData(),
                        lvtidnuvem: response.data.data.id,
                    });
                }
            })
            .catch(error => console.error(error));
        }
    }
}