<template>
  <div class="wrapper">
    <video
      class="video"
      :class="{ user: facingMode === 'environment', filming: isFilming }"
      ref="video"
      muted
      id="blinkVideo"
    />
    <canvas style="display: none" ref="canva" />
    <div 
    class="relogio"
    v-if="isFilming"
    >
        {{$$_tempoFormatado}}
    </div>
<!--
    <button
      class="button is-rounded is-outlined switch-button"
      @click="switchCamera"
      :disabled="switchingCamera"
    >
      <b-icon pack="fas" icon="sync-alt" />
    </button> -->
    <div class="photo-button-container">
      <button 
      v-if='!isFilming' 
      class="button photo-button"
      @click="BeginFilm();">
        <v-icon 
        color="red"
        >mdi-circle</v-icon>
      </button>
      <button 
      v-else 
      class="button photo-button"
      @click="EndFilm();">
        <v-icon 
        color="red"
        small>mdi-square</v-icon>
      </button>
      <button 
      type="file" 
      v-if='delay' 
      class="button file-button" 
      @click="$_sendFile();">
        <v-icon>mdi-image</v-icon>
      </button>
      <!-- <button v-if='delay' class="button flash-button" @click="Flash();"
      id="flash"
      style='background: rgb(0,0,0,1);'>
        <v-icon v-if='flash'>mdi-white-balance-sunny</v-icon>
        <v-icon v-else style="color: white">mdi-weather-sunny</v-icon>
      </button> -->
    </div>
    <photos-gallery class="gallery" :photos="photos" />
    <input v-show="!iOS" type="range" orient="vertical" class="camera-slider">
    <v-snackbar
        v-model="errorPopUp"
        top
      >
        ERRO: {{ errorLog }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="red"
            v-bind="attrs"
            @click="errorPopUp = false"
          >
            OK
          </v-btn>
        </template>
      </v-snackbar>
      <input 
      type="file"
      multiple
      class="inputEl"
      @change="$_selecionarDaGaleria"
      accept="video/*"
      id="inputEl"
      >
      </div>
</template>

<script>
import PhotosGallery from '@/views/video/VideosGallery.vue';
import errorHandler from '@/utils/error-handler';
// import axios from '@@/node_modules/axios';

export default {
  components: {
    PhotosGallery,
  },
  data() {
    return {
      photos: [],
      mediaStream: null,
      videoDevices: [],
      facingMode: 'environment',
      counter: 0,
      switchingCamera: false,
      // Data criada por mim está abaixo.
      imageURL: null,
      errorPopUp: false,
      errorLog: undefined,
      file: '',
      flash: false,
      // delay é equivalente a invisible, block é equivalente a enabled.
      delay: true,
      cameraDelay: 0,
      iOS: false,
      fileLoaderIndex: 0,
      reader: new FileReader(),

      mediaRecorder: null,
      recordedChunks: [],
      isFilming: false,
      relogio: 0,
      relogioInterval: null,

      // Modo Dev, ficar subindo pra amazon cansa
      dev: true,
      devUrl: 'https://400d0457-82dc-4a9a-b420-09666610ea95.mdnplay.dev/shared-assets/videos/flower.webm'
    };
  },
  computed: {
    $$_tempoFormatado() {
      let minutos = Math.floor(this.relogio / 60);
      let segundos = this.relogio % 60;
      return `${String(minutos).padStart(2, '0')}:${String(segundos).padStart(2, '0')}`;
    }
  },
  methods: {

    handleFileUpload() {
      // eslint-disable-next-line prefer-destructuring
      this.file = this.$refs.file.files[0];
      console.log(this.file);
    },

    async StartRecording(facingMode) {
      try {
        this.facingMode = facingMode;
        const { video } = this.$refs;
        video.setAttribute('playsinline', true);
        video.setAttribute('autoplay', '');
        video.setAttribute('muted', true);

        if (this.iOS) {
          this.mediaStream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode },
            audio: true,
          });
          video.srcObject = this.mediaStream;
        } else {
          this.mediaStream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode },
            audio: true,
          });
          video.srcObject = this.mediaStream;
          const [track] = this.mediaStream.getVideoTracks();
          const capabilities = track.getCapabilities();
          const input = document.querySelector('input[type="range"]');
          const settings = track.getSettings();
          input.min = capabilities.zoom.min;
          input.max = capabilities.zoom.max;
          input.step = capabilities.zoom.step;
          input.value = settings.zoom;
          input.oninput = ((event) => {
            track.applyConstraints({ advanced: [{ zoom: event.target.value }] });
          });
          input.hidden = false;
        }

        // this.mediaRecorder = new MediaRecorder(this.mediaStream);
        this.mediaRecorder = new MediaRecorder(this.mediaStream, {
          mimeType: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        });

        this.recordedChunks = [];

        this.mediaRecorder.ondataavailable = event => {
          if (event.data.size > 0) {
            this.recordedChunks.push(event.data);
          }
        };

        this.mediaRecorder.onstop = () => {
          try {

            const blob = new Blob(this.recordedChunks, { type: 'video/mp4' });
            const url = URL.createObjectURL(blob);

            console.log(blob, url, 'blob');

            this.photos.push({
              id: this.counter += 1,
              src: url,
              blob: blob,
              galeria: false,
            });
            this.fileLoaderIndex += 1;

            this.recordedChunks = [];
          } catch (err) {
            if (typeof errorHandler === 'function') {
              errorHandler(err);
            } else {
              console.error("Erro ao finalizar gravação:", err);
            }
          }
        };

        this.mediaRecorder.onerror = (event) => {
          errorHandler(event.error);
        };

        // eslint-disable-next-line no-return-await
        return await video.play();
      } catch {
        if (this.iOS) {
          errorHandler('Dispositivo de mídia inválido ou inexistente (iOS)');
        } else {
          errorHandler('Dispositivo de mídia inválido ou inexistente');
        }
      }
      return null;
    },

    async BeginFilm() {
      if (this.mediaRecorder) {
        try {
            this.isFilming = true;
            this.mediaRecorder.start();
            const { video } = this.$refs;
            video.setAttribute('muted', true);
            this.relogioInterval = setInterval(() => {
              this.relogio += 1;
            }, 1000);
        } catch (err) {
            errorHandler(err);
        }
      }
    },

    async EndFilm() {
      if (this.mediaRecorder) {
        try {
          this.isFilming = false;
          this.mediaRecorder.stop();
          clearInterval(this.relogioInterval);
          this.relogio = 0;
        } catch (err) {
          errorHandler(err);
        }
      }
    },

    async switchCamera() {
      this.switchingCamera = true;
      const tracks = this.mediaStream.getVideoTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      await this.StartRecording(
        this.facingMode === 'environment' ? 'user' : 'environment',
      );
      this.switchingCamera = false;
    },

    async Blink(el, duration) {
      const element = el;
      element.style.opacity = 0;
      setTimeout(() => { element.style.opacity = 1; }, duration);
    },

    resetSlider() {
      const input = document.querySelector('input[type="range"]');
      const [track] = this.mediaStream.getVideoTracks();
      const capabilities = track.getCapabilities();
      input.value = capabilities.zoom.min;
      track.applyConstraints({ advanced: [{ zoom: input.value }] });
    },

    Flash() {
      this.flash = !this.flash;
      if (!this.flash) {
        document.getElementById('flash').style.background = 'rgb(0,0,0,0.4)';
      } else {
        document.getElementById('flash').style.background = 'rgb(255,255,255,1)';
      }
    },

    getOS() {
      if (navigator.appVersion.indexOf('like Mac') > -1) {
        this.iOS = true;
      }
    },

    async $_sendFile() {
      document.getElementById('inputEl').value = null;
      document.getElementById('inputEl').click();
    },

    async $_selecionarDaGaleria(event) {
      const files = event.target.files;

      if (!files || files.length === 0) return;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const blob = new Blob([file], { type: file.type });
        const url = URL.createObjectURL(blob);

        this.photos.push({
          id: this.counter += 1,
          src: url,
          blob: blob,
          galeria: true,
        });
      }
    }
  },

  async mounted() {
    let devices;
    try {
      devices = await navigator.mediaDevices.enumerateDevices();
    } catch (err) {
      errorHandler('Não existem dispositivos compativeis');
      console.log(err);
      return;
    }
    this.videoDevices = devices.filter((d) => d.kind === 'videoinput');
    await this.StartRecording(
      'environment',
    );
    // this.videoDevices.length === 1 ? 'user' : 'environment', IA DENTRO DO STARTRECORDING
    if (this.iOS && this.facingMode === 'user') {
      this.switchCamera();
    }
    try {
      if (!this.iOS) {
        this.resetSlider();
      }
    } catch {
      if (this.iOS) {
        errorHandler('Erro ao ativar a ferramenta de zoom (iOS)');
      } else {
        errorHandler('Erro ao ativar a ferramenta de zoom');
      }
    }

    this.Flash();
  },

  beforeMount() {
    this.getOS();
  },
};
</script>

<style scoped>

.video.front {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.wrapper {
  background-color: black;
  display: grid;
  left: 0px;
  top:  0px;
  width: 100vw;
  height: 100vh;
  grid-template-columns: [left] 90vw [bs] 5vw [es] 5vw [right];
  grid-template-rows: [top] 5vh [bs] 5vh [es] 60vh [middle] 10vh [bottom] 20vh [end];
  justify-items: center;
  overflow: hidden;
  position: absolute;
}

.video {
  height: 100%;
  width: 100%;
  grid-column: left/right;
  grid-row: top / bottom;
  user-select: none;
  max-width: 100%;
  max-height: 76%;
  position: fixed;
  left: 0%;
  overflow:hidden;
}

.switch-button {
  grid-column: bs / es;
  grid-row: bs / es;
  z-index: 5;
  border-radius: 100%;
  position: fixed;
  right: 7vh;
  top: 10vh;
  width: 6vh;
  height: 6vh;
  font-size: 2vh;
}

.photo-button-container { 
  grid-column: left / right;
  grid-row: middle / bottom;
  z-index: 5;
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: center;
}

.photo-button {
  width: 8vh;
  height: 8vh;
  border-radius: 100%;
  position: fixed;
  bottom: 16vh;
  background: rgb(255,255,255,1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-button {
  font-size: 4vh;
  color: rgb(0, 0, 0);
}

.file-button {
  width: 7vh;
  height: 7vh;
  border-radius: 100%;
  right: 4vw;
  position: fixed;
  bottom: 16vh;
  background: rgb(255,255,255,0.4);
}

.file-button {
  font-size: 3vh;
  color: rgb(0, 0, 0);
}

.flash-button{
  width: 6.8vh;
  height: 6.8vh;
  border-radius: 100%;
  position: fixed;
  bottom: 26vh;
  right: 6vw;
  background: rgb(255,255,255,0.4);
}

.gallery {
  grid-column: left / right;
  grid-row: bottom / end;
  z-index: 10;
  position: fixed;
  height: 15%;
  bottom: 0;
}

.camera-slider {
  -webkit-appearance: slider-vertical;
  position: fixed;
  top: 35%;
  right: -8vw;
}

.inputEl {
  display: none;
}

.relogio {
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 5px;
  padding-inline: 12px;
  position: fixed;
  top: 70px;
  border-radius: 5px;
}

.filming {
  box-shadow:inset 0px 0px 0px 3px #f00;
}

</style>
