<template>
  <div :class="minimizedInput ? 'containerMinimized' : 'container'" 
    v-if="dados">
    <div class="codigo-container">
      <v-text-field
        v-if="objectReturn"
        v-bind="$attrs"
        v-on="$listeners"
        outlined
        :dense="dense"
        background-color="white"
        hide-details="true"
        class="codigo"
        :label="labelCodigo"
        v-model="codigo[itemvalue]"
        @input="$_handleInput()"
        @change="$_validaCodigo(codigo);"
      />
      <v-text-field
        v-else
        v-bind="$attrs"
        v-on="$listeners"
        outlined
        :dense="dense"
        type="tel"
        background-color="white"
        hide-details
        class="codigo"
        :label="labelCodigo"
        v-model="codigo"
        @input="$_handleInput()"
        @change="$_validaCodigo(codigo);"
      />
      <v-btn class="botao" color="primary" @click="filtro = true">
        <v-icon class="text-h4">mdi-magnify</v-icon>
      </v-btn>
    </div>
    <v-autocomplete
      v-bind="$attrs"
      v-on="$listeners"
      outlined
      :dense="dense"
      background-color="white"
      :items="dados"
      :item-text="itemlabel"
      :item-value="itemvalue"
      :class="minimizedInput ? 'descricaoMinimized' : 'descricao'"
      :label="labelTexto"
      @input="$_handleInput()"
      v-model="codigo"
      :return-object="objectReturn"
      @change="$_emiteAlteracao(codigo)"
    />
    <pesquisa-campo
      :label="labelTexto"
      :itemlabel="itemlabel"
      :itemvalue="itemvalue"
      :itemdetail="itemdetail"
      :load="load"
      :filterString="filterString"
      v-model="filtro"
      transition="dialog-bottom-transition"
      @retorno="$_retorno"
      :radiolabel="radiolabel"
    />
  </div>
</template>

<script>
import errorHandler from '@/utils/error-handler';
import PesquisaCampo from '@/components/core/pesquisa-campo/Index.vue';

export default {
  name: 'FormMultiField',

  components: {
    PesquisaCampo,
  },

  props: {
    load: {
      type: Array,
      default: (() => ([])),
    },
    value: null,
    labelTexto: {
      type: String,
      default: 'Descrição',
    },
    labelCodigo: {
      type: String,
      default: 'Codigo',
    },
    itemvalue: {
      type: String,
    },
    itemlabel: {
      type: String,
    },
    itemdetail: {
      type: String,
      default: '',
    },
    objectReturn: {
      type: Boolean,
      default: false,
    },
    filterString: {
      type: Boolean,
    },
    radiolabel: {
      type: Array,
      default: (() => ['Codigo', 'Descrição']),
    },
    minimizedInput: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
    },
    clearInput: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      codigo: this.value,
      descricao: '',
      filtro: false,
      dados: null,
    };
  },

  /*

  ESSE MULTIFIELD FUNCIONA REBENDO ARRAY, DIFERENTE DO MULTIFIELD DO FOOD, QUE RECEBE UM METODO

  */

  methods: {
    async $_validaCodigo(codigo) {
      const validado = await this.dados.filter((obj) => obj[this.$props.itemvalue] === codigo);
      if (!validado.length) {
        errorHandler('Não existe um item com este codigo');
        this.codigo = null;
        return;
      }
      this.$emit('validated-change', codigo);
    },

    async $_emiteAlteracao(codigo) {
      this.$emit('on-change', codigo);
    },

    async $_retorno(valor) {
      console.log(valor, 'valor');
      setTimeout(() => {
        this.$emit('filtro', valor);
        this.codigo = valor;
        this.filtro = false;
      });
    },

    $_handleInput() {
      this.$emit('input', this.codigo);
    },
  },

  created() {
    this.dados = this.$props.load;
  },
};
</script>

<style scoped>
  .container {
    display: flex;
    padding: 0;
  }

  .codigo-container {
    width: 160px;
    display: flex;
  }

  .botao {
    width: 5px;
    margin-left: 10px;
    height: 5px;
  }

  .descricao {
    width: 60%;
    margin-left: 15px;
  }

  @media screen and (max-width: 480px) {
    .container {
      flex-direction: column;
    }

    .codigo-container {
      width: 170px;
      display: flex;
    }

    .codigo {
      width: 80%;
    }

    .botao {
      width: 5px;
      height: 5px;
      margin-left: 15px;
    }

    .descricao {
      width: 100%;
      margin-left: 0px;
      margin-top: 15px;
    }
  }

  .descricaoMinimized {
    width: 50%;
    margin-left: 15px;
  }
  .containerMinimized {
    display: flex;
    max-height: 60px;
    padding: 0;
    flex-direction: none;
  }

</style>

/* CHANGELOG */
/*
  V2: Adicionado Action aos props, para alimentar a função de clique externamente.
*/
