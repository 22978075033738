<template>
  <div class="my-3">
      <table class="tabela" v-if="possuiItem === 'S'">
        <tr class='header-tabela'>
          <th class="col1-header">
            ID
          </th>
          <th class="col2-header">
            UN
          </th>
          <th class="col2-header">
            Produto
          </th>
          <th class="col3-header">
            Qtde
          </th>
          <th class="col4-header">
            Preço
          </th>
          <th class="col5-header">
            Total
          </th>
        </tr>
        <tr class='claro tabela-interna'
        :class="{ escuro: (index % 2 === 1) }"
        v-for="(data, index) in dados" :key="index" @click="$_editaItem($event, data)">
          <td class="col1" v-if="data.proidproduto">
            {{ data.proidproduto }}
          </td>
          <td v-else class="col1">  </td>
          <td v-if="data.proun" class="col2">
            {{data.proun}}
          </td>
          <td v-else class="col2">  </td>
          <td v-if="data.prodescricao" class="col3">
            {{data.prodescricao}}
          </td>
          <td v-else class="col3">  </td>
          <td v-if="data.proquantidade" class="col4">
            {{data.proquantidade}}
          </td>
          <td v-else class="col4">  </td>
          <td v-if="data.provlunitario" class="col5">
            {{data.provlunitario}}
          </td>
          <td v-else class="col5">  </td>
          <td v-if="data.prototal" class="col5">
            {{new Intl.NumberFormat("pt-BR", {
                minimumFractionDigits: 4,
                maximumFractionDigits: 4,
              }).format(data.prototal)}}
          </td>
          <td v-else class="col5">  </td>
        </tr>
      </table>
      <table class="tabela" v-if="possuiItem === 'S'">
        <tr class='tabela-interna'
        :class="{ escuro: true }">
          <td class="total__label">
            TOTAL
          </td>
          <td class="total__valor">
            {{$$_total}}
          </td>
        </tr>
      </table>
      <div v-else-if="possuiItem === 'N'" class="no-data">
        <v-icon size="80px">
          $vuetify.icons.NFe
        </v-icon>
        <h4>
          Não existem produtos vinculados a está NF-e
        </h4>
      </div>
      <div v-else-if="possuiItem === 'C'" class="carregando">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <h4>
          Atualizando...
        </h4>
      </div>
      <v-dialog v-model="dialog" content-class="teste" hide-overlay>
        <v-card>
          <v-card-title class="titulo pa-2">
            <div>
              {{dataDialog.proidcodigo}}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <div class="linha-dialog center">
            <b>{{dataDialog.prodescricao}}</b>
          </div>
          <v-divider></v-divider>
          <div class="linha-dialog">
            <div>
              Quantidade
            </div>
            <div>
              {{dataDialog.proquantidade}}
            </div>
          </div>
          <v-divider></v-divider>
          <div class="linha-dialog">
            <div>
              Preço Unitario:
            </div>
            <div>
              R$ {{dataDialog.provlunitario}}
            </div>
          </div>
          <v-divider></v-divider>
          <div class="linha-dialog">
            <b>
              Total:
            </b>
            <b>
              R$ {{$_currency(dataDialog.prototal)}}
            </b>
          </div>
          <v-divider></v-divider>
          <v-card-actions class="acoes">
            <v-btn color="primary" @click="$_retorno()">Voltar</v-btn>
            <v-btn color="#af4c4c" dark @click="$_deletar()">Deletar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
/* 
import { PRODUTO_QUERIES } from '@/constants/QUERY_FILTERS';
import { getQueryValue } from '@/services/query-string'; */

import { nextTick } from 'vue';

export default {
  name: 'CoreTabela',

  props: {
    readonly: Boolean,
    produtos: Array,
  },

  data() {
    return {
      loading: false,
      dados: [],
      dialog: false,
      dataDialog: {
        prodescricao: '',
        pviquantidade: '',
        pviprecounitario: '',
        proreferencia: '',
      },
      possuiItem: 'N',
    };
  },

  methods: {

    $_editaItem(e, data) {
      if (this.$props.readonly) {
        return;
      }
      if (this.selecionado) {
        this.selecionado.parentElement.classList.remove('selecionado');
      }
      if (this.dialog === false) {
        this.selecionado = e.target;
        this.selecionado.parentElement.classList.add('selecionado');
        this.dataDialog = data;
        console.log('datadialog',this.dataDialog);
        this.dialog = true;
      }
    },

    $_retorno() {
      this.selecionado.parentElement.classList.remove('selecionado');
      this.dialog = false;
    },

    $_deletar() {
      this.$_retorno();
      this.$emit('deletar', this.dataDialog);
    },
    
    $_currency(valor) {
      return new Intl.NumberFormat("pt-BR", {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        }).format(valor)
    }
  },

  created() {
    this.dados = JSON.parse(sessionStorage.getItem('produtos'))
    this.possuiItem = this.dados.length > 0 ? 'S' : 'N';
  },

  computed: {
    $$_total() {
      return (
        this.$_currency(this.dados.reduce(
          (acumulator, item) => acumulator + parseFloat(item.prototal),
          0,
        ))
      )
    },
  },

  watch: {
    dialog() {
      if (this.dialog === false) {
        this.$_retorno();
      }
    },
    produtos: {
      handler() {
        console.log(JSON.parse(sessionStorage.getItem('produtos')), 'produtos');
        this.dados = JSON.parse(sessionStorage.getItem('produtos'))// this.$props.produtos;
        sessionStorage.setItem('total', this.$$_total);
        nextTick(() => {
          this.possuiItem = this.dados.length ? 'S' : 'N';
        });
        console.log(this.dados, 'dados');
      }
    }
  },
};
</script>
<style scoped>
.tabela-interna {
  width: 100%;
  font-size: 12px;
}

.tabela {
  width: 100%;
  max-height: 300px !important;
  overflow: scroll !important;
  padding: 0px;
  background-color: rgb(27, 83, 5);
}

.claro {
  background-color: #f4faf5;
}

.escuro {
  background-color: #d5ebd2;
}

.selecionado {
  background-color: #af4c4c;
  color: white;
}

.header-tabela {
  width: 100%;
  background-color: #4caf54;
    color: white;
  font-size: 13px;
}

.col1-header, .col1 {
  width: 5%;
  text-align: center;
}

.col2-header, .col2 {
  width: 10%;
  text-align: center;
}

.col2 {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 0.8em;
  white-space: nowrap;
}

.col3-header, .col3 {
  width: 24%;
  text-align: center;
}

.col5-header, .col5, .col4-header, .col4 {
  width: 20%;
  text-align: center;
}

.col1, .col2, .col3, .col4, .col5 {
  font-size: 13px;
  padding: 2px;
}

.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carregando {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-data > h4 {
  text-align:center;
  width: 66%;
}

>>> .teste {
  transform: translateY(-200px);
  max-width: 500px;
}

.linha-dialog {
  display: flex;
  justify-content: space-between;
  padding: 2px 5px 2px 5px;
}

.titulo {
  color: white;
  background-color: #57af4c;
  display: flex;
  flex-direction: column;
}

.acoes {
  display: flex;
  justify-content: space-between;
}

.center {
  justify-content: center;
}

.total__label {
  padding-left: 25px;
  background-color: #9bcf95;
  font-weight: bold;
}

.total__valor {
  width: 20%;
  text-align: center;
  padding: 2;
  font-size: 12px;
  background-color: #9bcf95;
  font-weight: bold;
}
</style>
