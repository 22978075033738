import db from "@/plugins/dexie";
import { WsEnviaDados } from "../../websocket";
import geraDataV2 from "@/utils/geraDataV2";

export default {
    enviaNotaFiscalItem: () => {
        const notafiscalitem = db.notafiscalitem.where('nfiidnuvem').equals('null').toArray();

        notafiscalitem.then(async (tabela) => {

            const items = [...tabela]
            console.log('notasitems', items)
            if (items.length !== 0) {
                await WsEnviaDados({tipo: 'postNotaFiscalItem', msg: items})
            }
        });
    },
    retornoNotaFiscalItem: ({ data }) => {
        const item = data.body;
        const queryPromise = db.notafiscalitem.where({nfidataatualizacao: geraDataV2({date:item.nfidataatualizacao}) }).toArray();
        queryPromise.then((tabela) => {
        tabela.forEach((linha) => {
            db.notafiscalitem.update(linha.id, { nfiidnuvem: item.id, nfidatanuvem: geraDataV2({date:item.nfidataatualizacao}) })
            })
        })
    },
}